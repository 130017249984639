import React, { useContext, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import Divider from '@material-ui/core/Divider';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import PropTypes from 'prop-types';
import { Grid, CardHeader, Button, Accordion, AccordionSummary, AccordionDetails, FormGroup, FormControlLabel, Checkbox, GridList, GridListTile, GridListTileBar, Stepper, Step, StepLabel, StepContent, Avatar, Table, TableContainer, Paper, TableHead, TableRow, TableCell, TableBody } from '@material-ui/core';
import { grey, red } from '@material-ui/core/colors';
import { formatNumber, format_display_date, format_display_fancy_notime, getTimeInIST, openLink } from '../Utils';
import { AppContext } from '../AppContextProvider';
import { STATUS, STEP_TYPE, USERTYPES } from '../Constants';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import queryString from 'query-string';
import { useHistory } from 'react-router-dom';
import { LeadService } from '../Services/LeadService';
import GetAppIcon from '@material-ui/icons/GetApp';
import MobileStepper from '../components/Stepper';
import { CheckCircle, Cancel } from '@material-ui/icons';
import clsx from 'clsx';
import CustomStepLabel from './CustomStepLabel';
import CustomStepContent from './CustomStepContent';
import UpdateLeadDialog from '../components/UpdateLeadDialog';
import EditIcon from '@material-ui/icons/Edit';
import { isEmpty } from 'lodash';

const useStyles = makeStyles((theme) => ({
	appBar: {
		position: 'relative',
	},
	accordionSummary: {
		backgroundColor: theme.palette.white,
		color: theme.palette.white
	},
	title: {
		marginLeft: theme.spacing(2),
		flex: 1,
		color: theme.palette.white
	},
	header: {
		fontWeight: 700
	},
	gridList: {
		margin: 16,
		width: '100%'
	},
	page: {
		height: '100%',
		backgroundColor: theme.palette.divider,
		overflow: 'auto'
	},
	section: {
		margin: 16,
		minHeight: 200,
	},
	avatar: {
		backgroundColor: red[500],
	},
	numberField: {
		textAlign: 'right',
		width: '8vw'
	},
	image: {
		margin: 12,
		position: 'relative',
		height: 200,
		width: 200,
		[theme.breakpoints.down('xs')]: {
			width: '100% !important', // Overrides inline-style
			height: 100,
		},
		'&:hover,  &Mui-focusVisible': {
			zIndex: 1,
			'& $imageBackdrop': {
				opacity: 0.7,
			},
			'& $imageMarked': {
				opacity: 0,
			},
			'& $imageTitle': {
				border: '4px solid currentColor',
			},
		},
	},
	imageBackdrop: {
		borderRadius: 8,
		position: 'absolute',
		left: 0,
		right: 0,
		top: 0,
		bottom: 0,
		backgroundColor: theme.palette.common.black,
		opacity: 0.3,
		transition: theme.transitions.create('opacity'),
	},
	imageTitle: {
		position: 'relative',
		padding: `${theme.spacing(0)}px ${theme.spacing(4)}px ${theme.spacing(0)}px`,
	},
	imageMarked: {
		height: 3,
		width: 18,
		backgroundColor: theme.palette.common.white,
		position: 'absolute',
		bottom: -2,
		left: 'calc(50% - 9px)',
		transition: theme.transitions.create('opacity'),
	},
	imageButton: {
		position: 'absolute',
		left: 0,
		right: 0,
		bottom: 0,
		top: 0,
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		color: theme.palette.common.white,
	},
	imageButtonBottom: {
		position: 'absolute',
		left: 0,
		right: 0,
		// top: 0,
		// bottom: 0,
		bottom: 1,
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		color: theme.palette.common.white,
	},
	formAutoClass: {
		zIndex: 3,
	},
	backdrop: {
		zIndex: theme.zIndex.drawer + 1,
		color: '#fff',
	},
	card: {
		margin: 8
	},
	actionsContainer: {
		marginBottom: theme.spacing(2),
		marginLeft: theme.spacing(2)
	},
	button: {
		marginTop: theme.spacing(1),
		marginRight: theme.spacing(1),
	},
	icon: {
		color: theme.palette.white
	},
	iconT: {
		fontSize: 18,
		marginLeft: 16,
		[theme.breakpoints.down('lg')]: {
			marginTop: 4,
		},
		cursor: 'pointer',
	}
}));

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="up" ref={ref} {...props} />;
});

export default function ViewLeadDialog(props) {
	const { state, mobileView, openNewForm, showProgressDialog, closeProgressDialog, showConfirmDialog, closeConfirmDialog, showConfirmDialog1D, showSnackBar, closeConfirmDialog1D } = useContext(AppContext);
	const { id } = props;
	const classes = useStyles();
	const [open, setOpen] = React.useState(false);
	const [expandedDetails, setExpandedDetails] = React.useState(false);
	const [expandedProofs, setExpandedProofs] = React.useState(false);
	const [expandedSteps, setExpandedSteps] = React.useState(true);
	const [expandedStepDocs, setExpandedStepDocs] = React.useState(false);
	const [editEnabled, setEditEnabled] = React.useState(false);
	const [lead, setLead] = React.useState({});
	const [stepDocuments, setStepDocuments] = React.useState([]);
	const [completedCount, setCompleted] = React.useState(0);
	const [totalSteps, setTotal] = React.useState(0);
	const [activeStep, setActiveStep] = React.useState(0);
	const [step, setStep] = React.useState(null);

	const [openEdit, setOpenEdit] = React.useState(false);
	const [field, setField] = React.useState(undefined);
	const [value, setValue] = React.useState(undefined);
	const [label, setLabel] = React.useState(undefined);
	const [type, setType] = React.useState(undefined);

	// const [loading, setLoading] = React.useState(true);

	const [returnUrl, setReturnURL] = useState(false);
	const history = useHistory();

	React.useEffect(() => {
		if (props.location && props.location.search) {
			let qString = queryString.parse(props.location.search);
			if (qString.redirectUrl) {
				setReturnURL(qString.redirectUrl);
			}
		}
		setOpen(props.open);
		if (props.open) {
			fetchData();
		}
		return () => {
			setLead({});
			setStepDocuments([]);
			setOpen(false);
		}
	}, [props.open]);

	const fetchData = () => {
		// setLoading(true);
		showProgressDialog('Loading...');
		LeadService.getLeadById(id)
			.then(data => {
				console.log(data);
				if (data) {
					setLead(data);
					//Enable Edit fields
					if (data.steps && (state.user.EmpUtype === USERTYPES.BRANCH || state.user.EmpUtype === USERTYPES.STATEMG || state.user.EmpUtype === USERTYPES.SALEHD) && data.steps.filter(s => (s.step_id + '') === '1' || (s.step_id + '') === '2' || (s.step_id + '') === '3').filter(s => s.approved === 0).length > 0) {
						setEditEnabled(true)
					}

					if (data.steps && data.steps.length > 0) {
						let stepDocuments = [];
						data.steps.forEach(step => {
							if (step.documents && step.documents.length > 0) {
								stepDocuments.push(...step.documents);
							}
						});
						setStepDocuments(stepDocuments);

					}

					setTotal(data.steps.length);
					let completed = data.steps.filter(s => s.approved !== null && s.approved !== 0).length;
					// console.log(completed, data.steps.length);
					// if(completed > 0){
					// 	completed -= 1;
					// }
					setCompleted(completed);
					setActiveStep(completed);
					setStep(data.steps[completed]);
				}
				closeProgressDialog();
				// setLoading(false);
			})
			.catch(error => {
				console.log(error);
				showConfirmDialog1D('Something went wrong. Please try again.', undefined, () => {
					handleClose();
					closeConfirmDialog1D();
				});
				// setLoading(false);
				closeProgressDialog();
			});
	}

	const handleClose = (callback = false) => {
		props.onClose(callback);
		setLead({});
		setStepDocuments([]);
		setCompleted(0);
		setActiveStep(0);
		setStep(null);
		setTotal(0);
		setOpen(false);
		setEditEnabled(false);
		setExpandedDetails(false);
		setExpandedProofs(false);
		setExpandedSteps(true);
		if (returnUrl) {
			history.push(returnUrl);
		}
	};

	const getStepLabel = (step) => {
		if (!step.approved || step.approved === 0) {
			return step.name;
		} else {
			return <CustomStepLabel step={step} />
		}
	}

	const handleChange = (field, value) => {
		// console.log(field, value);
		setStep(st => ({ ...st, [field]: value }))
	}

	const handleSave = skipped => e => {
		let message = 'Confirm Save Task?';
		if (step.step_ui_type === STEP_TYPE.email) {
			let size = step.field1 ? step.field1.split(',').length : 0;
			message = `You have selected ${size} file${size > 0 ? 's' : ''}. Confirm Send Email?`;
		}
		if (skipped) {
			message = 'Confirm Skip Step?';
		}
		showConfirmDialog(message, undefined, () => {
			save(skipped);
		});
	}

	const updateLead = (field, value) => {
		showConfirmDialog('Confirm changes ?', undefined, () => {
			closeConfirmDialog();
			showProgressDialog('Saving...');
			LeadService.updateLead(lead.id, { key: field, value: value })
				.then(() => {
					lead[field] = value;
					closeProgressDialog();
				})
				.catch(error => {
					console.log(error);
					closeProgressDialog();
					showConfirmDialog1D('Something went wrong. Please try again.', undefined, () => {
						closeConfirmDialog1D();
					});
				});
		});
	}

	const openEditField = (label, type, field, value) => e => {
		setLabel(label)
		setType(type)
		setField(field)
		setValue(value)
		setOpenEdit(true)
	}

	const performInspection = e => {
		openNewForm(step.lead_id, step.step_ui_type.toUpperCase(), (saved) => {
			save(false);
		});
	}

	const saveWithData = (fields) => {
		Object.keys(fields).forEach(field => {
			step[field] = fields[field];
		})
		save();
	}

	const save = (skipped = false) => {
		if (skipped) {
			step.approved = 1;
			step.skipped = 1;
		}
		if (step.step_ui_type === STEP_TYPE.email || step.step_ui_type === STEP_TYPE.code
			|| step.step_ui_type === STEP_TYPE.check || step.step_ui_type === STEP_TYPE.sublist
			|| step.step_ui_type === STEP_TYPE.payment_link || step.step_ui_type === STEP_TYPE.inspection
			|| step.step_ui_type === STEP_TYPE.receipt || step.step_ui_type === STEP_TYPE.feedback
			|| step.step_ui_type === STEP_TYPE.email_custom_file || step.step_ui_type === STEP_TYPE.upload_img
			|| step.step_ui_type === STEP_TYPE.courier || step.step_ui_type === STEP_TYPE.welcome
			|| step.step_ui_type === STEP_TYPE.code2) {
			step.approved = 1;
		}
		closeConfirmDialog();
		showProgressDialog('Completing Task...');
		if (completedCount === totalSteps - 1) {
			step.finish = 1;
		}
		LeadService.saveStep(lead.id, step)
			.then(data => {
				closeProgressDialog();
				if (data.success) {
					showSnackBar(`Completed step: ${step.name}`, `success`)
					if (completedCount === totalSteps - 1) {
						showConfirmDialog1D('You have finished the Onboarding for the Lead.', undefined, () => {
							closeConfirmDialog1D();
						});
					}
					fetchData();
					// if(activeStep <= totalSteps){
					// 	setStep(lead.steps[activeStep + 1]);
					// 	setActiveStep((prevActiveStep) => prevActiveStep + 1);
					// 	setCompleted((prevActiveStep) => prevActiveStep + 1);
					// }
				}
			})
			.catch(error => {
				console.log(error);
				closeProgressDialog();
				showConfirmDialog1D('Something went wrong. Please try again.', undefined, () => {
					closeConfirmDialog1D();
				});
			});
	}

	const getAddress = (add1, add2, add3, city, state, pincode) => {
		const address = []
		if (add1 && add1 !== 'null') address.push(add1)
		if (add2 && add2 !== 'null') address.push(add2)
		if (add3 && add3 !== 'null') address.push(add3)
		if (city && city !== 'null') address.push(city)
		if (state && state !== 'null') address.push(state)
		if (pincode && pincode !== 'null') address.push(pincode)
		return (
			<>
				{address.map(a => <>{a}<br /></>)}
			</>
		)
	}

	return (
		<Dialog fullScreen open={open} onClose={handleClose} TransitionComponent={Transition}>
			<AppBar className={classes.appBar}>
				<Toolbar>
					<IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
						<CloseIcon />
					</IconButton>
					<Typography variant="h6" className={classes.title}>
						{lead.full_name ? `Lead : ${lead.full_name}` : ''}
					</Typography>
				</Toolbar>
			</AppBar>
			{/* <Backdrop className={classes.backdrop} open={loading} onClick={() => {}}>
				<div style={{padding: 50, backgroundColor: 'white', borderRadius: 8}}>
					<CircularProgress 
							size={40}
							thickness={4}/>
				</div>
			</Backdrop> */}
			<Grid container className={classes.page} style={lead.status && lead.status === STATUS.CANCELLED ? { backgroundColor: lead.status } : {}} justify="center" alignItems="flex-start">
				<Grid container item spacing={1} xs={12} lg={12} xl={10} justify={"center"} direction="row" style={{ marginTop: 16 }}>
					{
						lead.id && (
							<Grid item xs={12} lg={12}>
								<Accordion square expanded={expandedDetails} onChange={() => setExpandedDetails(prev => (!prev))}>
									<AccordionSummary
										className={classes.accordionSummary}
										expandIcon={<ExpandMoreIcon />}
										aria-controls="panel1a-content"
										id="panel1a-header"
									>
										<CardHeader
											title="Lead Information"
											titleTypographyProps={{ variant: 'h4' }}
										/>
									</AccordionSummary>
									<AccordionDetails>
										<div style={{ marginRight: 16, marginLeft: 16 }}>
											<Grid container justify="center" spacing={2} alignItems="center">
												<Grid item xs={12} lg={12} container justify="flex-start" spacing={1} alignItems="center">
													<Grid item xs={12} lg={12}>
														<Divider />
													</Grid>
													<Grid item xs={12} lg={3}>
														<Typography variant="h5" className={classes.header}>Full Name</Typography>
													</Grid>
													<Grid item xs={12} lg={3}>
														<Typography variant="subtitle1">{lead.full_name || ''}</Typography>
													</Grid>
													<Grid item xs={12} lg={3}>
														<Typography variant="h5" className={classes.header}>Father / Husband Name</Typography>
													</Grid>
													<Grid item xs={12} lg={3}>
														<Typography variant="subtitle1">{lead.father_husband_name || ''}</Typography>
													</Grid>
													<Grid item xs={12} lg={3}>
														<Typography variant="h5" className={classes.header}>Branch</Typography>
													</Grid>
													<Grid item xs={12} lg={9}>
														<Typography variant="subtitle1">{lead.branch || ''}</Typography>
													</Grid>
													<Grid item xs={12} lg={12}>
														<Divider />
													</Grid>
													<Grid item xs={12} lg={3}>
														<Typography variant="h5" className={classes.header}>Office Address</Typography>
													</Grid>
													<Grid item xs={12} lg={3}>
														<Typography variant="subtitle1">
															{getAddress(lead.off_add1, lead.off_add2, lead.off_add3, lead.off_city, lead.off_state, lead.off_pincode)}
														</Typography>
													</Grid>
													<Grid item xs={12} lg={3}>
														<Typography variant="h5" className={classes.header}>Residence Address</Typography>
													</Grid>
													<Grid item xs={12} lg={3}>
														<Typography variant="subtitle1">
															{getAddress(lead.res_add1, lead.res_add2, lead.res_add3, lead.res_city, lead.res_state, lead.res_pincode)}
														</Typography>
													</Grid>
													<Grid item xs={12} lg={3}>
														<Typography variant="h5" className={classes.header}>Mobile</Typography>
													</Grid>
													<Grid item xs={12} lg={3}>
														<Typography variant="subtitle1" color="primary">{lead.mobile || '-'}</Typography>
													</Grid>
													{/* <Grid item xs={12} lg={3}>
													<Typography variant="h5" className={classes.header}>Landline</Typography>
												</Grid>
												<Grid item xs={12} lg={3}>
													<Typography variant="subtitle1" color="primary">{lead.landline || '-'}</Typography>
												</Grid> */}
													<Grid item xs={12} lg={3}>
														<Typography variant="h5" className={classes.header}>Email</Typography>
													</Grid>
													<Grid item xs={12} lg={3}>
														<Typography variant="subtitle1" color="primary">{lead.email || '-'}</Typography>
													</Grid>
													<Grid item xs={12} lg={3}>
														<Typography variant="h5" className={classes.header}>PAN</Typography>
													</Grid>
													<Grid item xs={12} lg={3}>
														<Typography variant="subtitle1">{lead.pan || '-'}</Typography>
													</Grid>
													<Grid item xs={12} lg={3}>
														<Typography variant="h5" className={classes.header}>Income Range (p.a)</Typography>
													</Grid>
													<Grid item xs={12} lg={3}>
														<Typography variant="subtitle1">{lead.income_range || '-'}</Typography>
													</Grid>
													<Grid item xs={12} lg={3}>
														<Typography variant="h5" className={classes.header}>Type of Franchisee</Typography>
													</Grid>
													<Grid item xs={12} lg={3}>
														<Typography variant="subtitle1">{lead.org_type || '-'}</Typography>
													</Grid>
													<Grid item xs={12} lg={12}>
														<Divider />
													</Grid>
													<Grid item xs={12} lg={3}>
														<Typography variant="h5" className={classes.header}>Qualification</Typography>
													</Grid>
													<Grid item xs={12} lg={9}>
														<Typography variant="subtitle1">{lead.qualification || ''}</Typography>
													</Grid>
													<Grid item xs={12} lg={12}>
														<Divider />
													</Grid>
													<Grid item xs={12} lg={3}>
														<Typography variant="h5" className={classes.header}>Segments Applied</Typography>
													</Grid>
													<Grid item xs={12} lg={9}>
														<FormGroup row>
															<FormControlLabel
																control={
																	<Checkbox
																		checked={lead.option_mcx === 1}
																		name="checkedB"
																		color="primary"
																	/>
																}
																label="MCX"
															/>
															<FormControlLabel
																control={
																	<Checkbox
																		checked={lead.option_ncdex === 1}
																		name="checkedB"
																		color="primary"
																	/>
																}
																label="NCDEX"
															/>
															<FormControlLabel
																control={
																	<Checkbox
																		checked={lead.option_nse_cash === 1}
																		name="checkedB"
																		color="primary"
																	/>
																}
																label="NSE Cash"
															/>
															<FormControlLabel
																control={
																	<Checkbox
																		checked={lead.option_nse_fo === 1}
																		name="checkedB"
																		color="primary"
																	/>
																}
																label="NSE F&O"
															/>
															<FormControlLabel
																control={
																	<Checkbox
																		checked={lead.option_nse_currency === 1}
																		name="checkedB"
																		color="primary"
																	/>
																}
																label="NSE Currencies"
															/>
															<FormControlLabel
																control={
																	<Checkbox
																		checked={lead.option_bse_cash === 1}
																		name="checkedB"
																		color="primary"
																	/>
																}
																label="BSE Cash"
															/>
															<FormControlLabel
																control={
																	<Checkbox
																		checked={lead.option_bse_fo === 1}
																		name="checkedB"
																		color="primary"
																	/>
																}
																label="BSE F&O"
															/>
														</FormGroup>
													</Grid>
													<Grid item container xs={12} lg={12}>
														<Grid item xs={12} lg={3}>
															<Typography variant="h5" className={classes.header}>Brokerage Sharing Commodity</Typography>
														</Grid>
														<Grid item>
															<Typography variant="subtitle1">{lead.brokerage_sharing_commodity || '-'}</Typography>
														</Grid>
														{
															(editEnabled) && (
																<Grid item>
																	<EditIcon className={classes.iconT} onClick={openEditField('Brokerage Sharing Commodity', 'text', 'brokerage_sharing_commodity', lead.brokerage_sharing_commodity || '')} />
																</Grid>
															)
														}
													</Grid>
													<Grid item container xs={12} lg={12}>
														<Grid item xs={12} lg={3}>
															<Typography variant="h5" className={classes.header}>Brokerage Sharing Capital</Typography>
														</Grid>
														<Grid item>
															<Typography variant="subtitle1">{lead.brokerage_sharing_capital || '-'}</Typography>
														</Grid>
														{
															(editEnabled) && (
																<Grid item>
																	<EditIcon className={classes.iconT} onClick={openEditField('Brokerage Sharing Capital', 'text', 'brokerage_sharing_capital', lead.brokerage_sharing_capital || '')} />
																</Grid>
															)
														}
													</Grid>
													<Grid item xs={12} lg={3}>
														<Typography variant="h5" className={classes.header}>Security Deposit</Typography>
													</Grid>
													<Grid item xs={12} lg={9}>
														<Typography variant="subtitle1">{lead.security_deposit || '-'}</Typography>
													</Grid>
													<Grid item xs={12} lg={3}>
														<Typography variant="h5" className={classes.header}>Terminal</Typography>
													</Grid>
													<Grid item xs={12} lg={9}>
														<Typography variant="subtitle1">{lead.terminal || '-'}</Typography>
													</Grid>
													<Grid item xs={12} lg={12}>
														<Divider />
													</Grid>
													<Grid item xs={12} lg={12}>
														<Typography variant="h5" className={classes.header}>Minimum to Company</Typography>
													</Grid>
													<Grid item container xs={12} lg={12}>
														<Grid item xs={12} lg={3}>
															<Typography variant="h5" className={classes.header}>Equity</Typography>
														</Grid>
														<Grid item>
															<Typography variant="subtitle1" className={classes.numberField}>{formatNumber(lead.mtc_equity || 0)}</Typography>
														</Grid>
														{
															(editEnabled) && (
																<Grid item>
																	<EditIcon className={classes.iconT} onClick={openEditField('Minimum To Company - Equity', 'number', 'mtc_equity', lead.mtc_equity || 0)} />
																</Grid>
															)
														}
													</Grid>
													<Grid item container xs={12} lg={12}>
														<Grid item xs={12} lg={3}>
															<Typography variant="h5" className={classes.header}>Commodity</Typography>
														</Grid>
														<Grid item>
															<Typography variant="subtitle1" className={classes.numberField}>{formatNumber(lead.mtc_commodities || 0)}</Typography>
														</Grid>
														{
															(editEnabled) && (
																<Grid item>
																	<EditIcon className={classes.iconT} onClick={openEditField('Minimum To Company - Commodity', 'number', 'mtc_commodities', lead.mtc_commodities || 0)} />
																</Grid>
															)
														}
													</Grid>
													<Grid item container xs={12} lg={12}>
														<Grid item xs={12} lg={3}>
															<Typography variant="h5" className={classes.header}>Options</Typography>
														</Grid>
														<Grid item>
															<Typography variant="subtitle1" className={classes.numberField}>{formatNumber(lead.mtc_options || 0)}</Typography>
														</Grid>
														{
															(editEnabled) && (
																<Grid item>
																	<EditIcon className={classes.iconT} onClick={openEditField('Minimum To Company - Options', 'number', 'mtc_options', lead.mtc_options || 0)} />
																</Grid>
															)
														}
													</Grid>
													{/* <Grid item xs={12} lg={12}>
														<Divider />
													</Grid>
													<Grid item xs={12} lg={3}>
														<Typography variant="h5" className={classes.header}>Software Charges</Typography>
													</Grid>
													<Grid item xs={12} lg={9}>
														<Typography variant="subtitle1" className={classes.numberField}>{formatNumber(lead.charges_software || 0)}</Typography>
													</Grid> */}
													<Grid item xs={12} lg={12}>
														<Divider />
													</Grid>
													<Grid item xs={12} lg={3}>
														<Typography variant="h5" className={classes.header}>Infrastructure details</Typography>
													</Grid>
													<Grid container item xs={12} lg={6} spacing={2}>
														<Grid item xs={6} lg={6}>
															<Typography variant="subtitle1">Number of computers (approx.)</Typography>
														</Grid>
														<Grid item xs={6} lg={6}>
															<Typography variant="subtitle1" className={classes.header}>{lead.infra_no_of_computers || 0}</Typography>
														</Grid>
														<Grid item xs={6} lg={6}>
															<Typography variant="subtitle1">Employees</Typography>
														</Grid>
														<Grid item xs={6} lg={6}>
															{!isEmpty(lead.infra_employees) ? (
																<TableContainer component={Paper}>
																	<Table>
																		<TableHead>
																			<TableRow>
																				<TableCell>Full name</TableCell>
																				<TableCell align="right">NISM Completed</TableCell>
																			</TableRow>
																		</TableHead>
																		<TableBody>
																			{lead.infra_employees.map(row => (
																				<TableRow
																					key={row.id}
																				>
																					<TableCell component="th" scope="row">
																						{row.name}
																					</TableCell>
																					<TableCell align='right'>
																						<Checkbox
																							checked={row.nism_completed === 1}
																							name="checkedB"
																							color="primary"
																						/>
																					</TableCell>
																				</TableRow>
																			))}
																		</TableBody>
																	</Table>
																</TableContainer>
															) : <Typography variant="subtitle1" className={classes.header}>-</Typography>}
															{/* <Typography variant="subtitle1" className={classes.header}>{lead.bank_branch}</Typography> */}
														</Grid>
													</Grid>
													<Grid item xs={12} lg={12}>
														<Divider />
													</Grid>
													<Grid item xs={12} lg={3}>
														<Typography variant="h5" className={classes.header}>Other AP Registration</Typography>
													</Grid>
													{
														lead.other_ap_name ? (
															<Grid container item xs={12} lg={6}>
																<Grid item xs={6} lg={6}>
																	<Typography variant="subtitle1">Name</Typography>
																</Grid>
																<Grid item xs={6} lg={3}>
																	<Typography align="right" variant="subtitle1" className={classes.header}>{lead.other_ap_name || '-'}</Typography>
																</Grid>
																<Grid item xs={6} lg={6}>
																	<Typography variant="subtitle1">Relation</Typography>
																</Grid>
																<Grid item xs={6} lg={3}>
																	<Typography align="right" variant="subtitle1" className={classes.header}>{lead.other_ap_relation || '-'}</Typography>
																</Grid>
																<Grid item xs={6} lg={6}>
																	<Typography variant="subtitle1">PAN</Typography>
																</Grid>
																<Grid item xs={6} lg={3}>
																	<Typography align="right" variant="subtitle1" className={classes.header}>{lead.other_ap_pan || '-'}</Typography>
																</Grid>
																<Grid item xs={6} lg={6}>
																	<Typography variant="subtitle1">Trading Member Name</Typography>
																</Grid>
																<Grid item xs={6} lg={3}>
																	<Typography align="right" variant="subtitle1" className={classes.header}>{lead.other_ap_trading_member_name || '-'}</Typography>
																</Grid>
																<Grid item xs={6} lg={6}>
																	<Typography variant="subtitle1">Franchisee Registration No</Typography>
																</Grid>
																<Grid item xs={6} lg={3}>
																	<Typography align="right" variant="subtitle1" className={classes.header}>{lead.other_ap_frch_reg_no || '-'}</Typography>
																</Grid>
																{/* <Grid item xs={6} lg={6}>
																	<Typography variant="subtitle1">Any past non-compliance observed from any exchange?</Typography>
																</Grid>
																<Grid item xs={6} lg={3}>
																	<Typography align="right" variant="subtitle1" className={classes.header}>{lead.other_ap_non_compliance === "1" || lead.other_ap_non_compliance === 1 ? "Yes" : "No"}</Typography>
																</Grid>
																<Grid item xs={6} lg={6}>
																	<Typography variant="subtitle1">Any existing investor compliant pending against this AP?</Typography>
																</Grid>
																<Grid item xs={6} lg={3}>
																	<Typography align="right" variant="subtitle1" className={classes.header}>{lead.other_ap_investor_complaint === "1" || lead.other_ap_investor_complaint === 1 ? "Yes" : "No"}</Typography>
																</Grid> */}
															</Grid>
														) : (
															<Grid item xs={6} lg={6}>
																<Typography variant="subtitle1">No</Typography>
															</Grid>
														)
													}
													<Grid item xs={12} lg={3} />
													<Grid item xs={12} lg={12}>
														<Divider />
													</Grid>
													<Grid item xs={12} lg={3}>
														<Typography variant="h5" className={classes.header}>Witness</Typography>
													</Grid>
													<Grid container item xs={12} lg={6} spacing={2}>
														<Grid item xs={6} lg={6}>
															<Typography variant="subtitle1">Do you personally know the AP</Typography>
														</Grid>
														<Grid item xs={6} lg={6}>
															<Typography variant="subtitle1" className={classes.header}>{lead.witness_know_the_ap === 1 ? "Yes" : "No"}</Typography>
														</Grid>
														<Grid item xs={6} lg={6}>
															<Typography variant="subtitle1">If yes, number of years</Typography>
														</Grid>
														<Grid item xs={6} lg={6}>
															<Typography variant="subtitle1" className={classes.header}>{lead.witness_no_of_years || '-'}</Typography>
														</Grid>
														<Grid item xs={12} lg={6}>
															<Typography variant="subtitle1" className={classes.header}><u>Witness 1:</u></Typography>
															<b>Name:</b> {lead.witness1_full_name}<br />
															<b>Address:</b><br />
															{getAddress(lead.witness1_add1, null, null, lead.witness1_city, lead.witness1_state, lead.witness1_pincode)}
														</Grid>
														<Grid item xs={12} lg={6}>
															<Typography variant="subtitle1" className={classes.header}><u>Witness 2:</u></Typography>
															<b>Name:</b> {lead.witness2_full_name}<br />
															<b>Address:</b><br />
															{getAddress(lead.witness2_add1, null, null, lead.witness2_city, lead.witness2_state, lead.witness2_pincode)}
														</Grid>
													</Grid>
													<Grid item xs={12} lg={12}>
														<Divider />
													</Grid>
													<Grid item xs={12} lg={3}>
														<Typography variant="h5" className={classes.header}>Terms & Conditions</Typography>
													</Grid>
													<Grid item xs={6} lg={6}>
														<FormControlLabel
															control={
																<Checkbox
																	checked={lead.tnc === 1}
																	name="checkedB"
																	color="primary"
																/>
															}
															label="Accepted"
														/>
													</Grid>
													<Grid item xs={12} lg={12}>
														<Divider />
													</Grid>
													<Grid item xs={12} lg={3}>
														<Typography variant="h5" className={classes.header}>Bank Details</Typography>
													</Grid>
													<Grid container item xs={12} lg={6}>
														<Grid item xs={6} lg={6}>
															<Typography variant="subtitle1">Bank Name</Typography>
														</Grid>
														<Grid item xs={6} lg={6}>
															<Typography variant="subtitle1" className={classes.header}>{lead.bank_name}</Typography>
														</Grid>
														<Grid item xs={6} lg={6}>
															<Typography variant="subtitle1">Branch</Typography>
														</Grid>
														<Grid item xs={6} lg={6}>
															<Typography variant="subtitle1" className={classes.header}>{lead.bank_branch}</Typography>
														</Grid>
														<Grid item xs={6} lg={6}>
															<Typography variant="subtitle1">Account No</Typography>
														</Grid>
														<Grid item xs={6} lg={6}>
															<Typography variant="subtitle1" className={classes.header}>{lead.bank_ac_no}</Typography>
														</Grid>
														<Grid item xs={6} lg={6}>
															<Typography variant="subtitle1">IFSC</Typography>
														</Grid>
														<Grid item xs={6} lg={6}>
															<Typography variant="subtitle1" className={classes.header}>{lead.bank_ifsc}</Typography>
														</Grid>
													</Grid>
													<Grid item xs={12} lg={3} />
													<Grid item xs={12} lg={12}>
														<Divider />
													</Grid>
													<Grid item xs={6} lg={3}>
														<Typography variant="h5" className={classes.header}>Entered By</Typography>
													</Grid>
													<Grid item xs={6} lg={9}>
														<Typography variant="subtitle1">{lead.entered_by || '-'}</Typography>
													</Grid>
													<Grid item xs={6} lg={3}>
														<Typography variant="h5" className={classes.header}>Branch Manager</Typography>
													</Grid>
													<Grid item xs={6} lg={9}>
														<Typography variant="subtitle1">{lead.bm_code || '-'}</Typography>
													</Grid>
													<Grid item xs={6} lg={3}>
														<Typography variant="h5" className={classes.header}>State Manager</Typography>
													</Grid>
													<Grid item xs={6} lg={9}>
														<Typography variant="subtitle1">{lead.sm_code || '-'}</Typography>
													</Grid>
													<Grid item xs={6} lg={3}>
														<Typography variant="h5" className={classes.header}>Sales Head</Typography>
													</Grid>
													<Grid item xs={6} lg={9}>
														<Typography variant="subtitle1">{lead.sh_code || '-'}</Typography>
													</Grid>
													<Grid item xs={12} lg={3}>
														<Typography variant="h5" className={classes.header}>Created On</Typography>
													</Grid>
													<Grid item xs={12} lg={3}>
														<Typography variant="subtitle1">{lead.created_date ? getTimeInIST(lead.created_date).format(format_display_fancy_notime) : '-'}</Typography>
													</Grid>
												</Grid>
											</Grid>
										</div>
									</AccordionDetails>
								</Accordion>
							</Grid>)
					}
					{
						lead.id && (
							<Grid item xs={12} lg={12}>
								<Accordion square expanded={expandedProofs} onChange={() => setExpandedProofs(prev => (!prev))}>
									<AccordionSummary
										className={classes.accordionSummary}
										expandIcon={<ExpandMoreIcon />}
										aria-controls="panel1a-content"
										id="proofs-header"
									>
										<CardHeader
											title={`Proofs (${lead.documents.length})`}
											titleTypographyProps={{ variant: 'h4' }}
										/>
									</AccordionSummary>
									<AccordionDetails>
										<div style={{ minHeight: 100, marginRight: 16, marginLeft: 16, marginTop: 8, marginBottom: 16 }}>
											<Grid container justify="flex-start" spacing={2} alignItems="center">
												<Grid item xs={12} lg={12}>
													<Divider />
												</Grid>
												<Grid item xs={12} lg={12}>
													<GridList cellHeight={200} className={classes.gridList} cols={mobileView ? 1 : 6}>
														{
															lead.documents.map((data, i) => {
																let type = data.document_type;
																switch (data.document_type) {
																	case "PHOTO":
																		type = "Photo";
																		break;
																	case "RES_ADD":
																		type = "Residence Address";
																		break;
																	case "OFF_ADD":
																		type = "Office Address";
																		break;
																	case "AADHAR":
																		type = "Aadhar";
																		break;
																	case "EQUITY_DER":
																		type = "Equity Derivatives";
																		break;
																	case "COMM_DER":
																		type = "Commodity Derivatives";
																		break;
																	case "CURR_DER":
																		type = "Currency Derivatives";
																		break;
																	case "NISM":
																		type = "NISM";
																		break;
																	case "OFFICE_SPACE":
																		type = "Office Space";
																		break;
																	case "PERSONAL_VISIT":
																		type = "Personal Visit";
																		break;
																	default:
																		break;
																}
																return (
																	<GridListTile key={i}>
																		<embed
																			width="200"
																			height="200"
																			name="plugin"
																			src={data.link}
																			type={data.type} />
																		<GridListTileBar
																			classes={{ root: classes.titleBar, subtitle: classes.subtitle }}
																			title={type}
																			subtitle={data.created_date ? getTimeInIST(data.created_date).format(format_display_date) : ''}
																			actionIcon={
																				<>
																					<IconButton aria-label={`download`} className={classes.icon} size="small" onClick={() => openLink(data.link)}>
																						<GetAppIcon />
																					</IconButton>
																				</>
																			}
																		/>
																	</GridListTile>
																)
															})
														}
													</GridList>
												</Grid>
											</Grid>
										</div>
									</AccordionDetails>
								</Accordion>
							</Grid>)
					}
					{
						lead.id && (
							<Grid item xs={12} lg={12}>
								<Accordion square expanded={expandedSteps} onChange={() => setExpandedSteps(prev => (!prev))}>
									<AccordionSummary
										className={classes.accordionSummary}
										expandIcon={<ExpandMoreIcon />}
										aria-controls="panel1a-content"
										id="panel1a-header"
									>
										<Grid container justify="space-between" alignItems="center">
											<Grid item>
												<CardHeader
													title={`Tasks`}
													titleTypographyProps={{ variant: 'h4' }}
													subheader={`Progress ${completedCount}/${totalSteps}`}
												/>
											</Grid>
											<Grid item xs={12} lg={6}>
												{
													!expandedSteps && (
														<div style={{ width: '100%' }}>
															<MobileStepper current={completedCount} total={totalSteps} />
														</div>
													)
												}
											</Grid>
										</Grid>
									</AccordionSummary>
									<AccordionDetails>
										<div style={{ minHeight: 100, marginRight: 16, marginLeft: 16, marginTop: 8, marginBottom: 8, width: '100%' }}>
											<Stepper orientation="vertical" activeStep={activeStep}>
												{
													lead.steps.map((step1, index) => {
														let userLevelDisabled = false;
														switch (`${step1.step_id}`) {
															case "1":
																userLevelDisabled = lead.bm_code.toLowerCase() !== state.user.EmpUcode.toLowerCase();
																break;
															case "2":
																userLevelDisabled = lead.sm_code.toLowerCase() !== state.user.EmpUcode.toLowerCase();
																break;
															case "3":
																userLevelDisabled = lead.sh_code.toLowerCase() !== state.user.EmpUcode.toLowerCase();
																break;
															default:
																userLevelDisabled = false;
																break;
														}

														let disabled = state.user.EmpUtype !== step1.ownership || userLevelDisabled;
														return (
															<Step key={index}>
																<StepLabel StepIconComponent={CustomStepIcon} error={step1.approved ? step1.approved === -1 : false} completed={step1.approved ? step1.approved === 1 : false}>
																	{getStepLabel(step1)}
																</StepLabel>
																<StepContent>
																	<CustomStepContent step={step} lead={lead} save={saveWithData} handleChange={handleChange} disabled={disabled} />
																	<div className={classes.actionsContainer}>
																		{
																			(step1.step_ui_type !== STEP_TYPE.courier && step1.step_ui_type !== STEP_TYPE.inspection && step1.step_ui_type !== STEP_TYPE.upload_img) && (
																				<Button
																					variant="contained"
																					color="primary"
																					disabled={disabled}
																					onClick={handleSave(false)}
																					className={classes.button}
																				>
																					{activeStep === (totalSteps.length - 1) ? 'Finish' : (step1.button_text || 'Save')}
																				</Button>
																			)
																		}
																		{
																			step1.skippable === 1 && (
																				<Button
																					variant="contained"
																					color="primary"
																					disabled={disabled}
																					onClick={handleSave(true)}
																					className={classes.button}
																				>
																					SKIP
																				</Button>
																			)
																		}
																		{
																			step1.step_ui_type === STEP_TYPE.inspection && (
																				<Button
																					variant="contained"
																					color="primary"
																					disabled={disabled}
																					onClick={performInspection}
																					className={classes.button}
																				>
																					Perform Inspection
																				</Button>
																			)
																		}
																	</div>
																	<Divider />
																</StepContent>
															</Step>
														)
													})
												}
											</Stepper>
										</div>
									</AccordionDetails>
								</Accordion>
							</Grid>)
					}
					{
						lead.id && stepDocuments && (
							<Grid item xs={12} lg={12}>
								<Accordion square expanded={expandedStepDocs} onChange={() => setExpandedStepDocs(prev => (!prev))}>
									<AccordionSummary
										className={classes.accordionSummary}
										expandIcon={<ExpandMoreIcon />}
										aria-controls="panel1a-content"
										id="proofs-header"
									>
										<CardHeader
											title={`Documents (${stepDocuments.length})`}
											titleTypographyProps={{ variant: 'h4' }}
										/>
									</AccordionSummary>
									<AccordionDetails>
										<div style={{ minHeight: 100, marginRight: 16, marginLeft: 16, marginTop: 8, marginBottom: 16 }}>
											<Grid container justify="flex-start" spacing={2} alignItems="center">
												<Grid item xs={12} lg={12}>
													<Divider />
												</Grid>
												<Grid item xs={12} lg={12}>
													<GridList cellHeight={330} className={classes.gridList} cols={mobileView ? 1 : 6}>
														{
															stepDocuments.map((data, i) => {
																return (
																	<GridListTile key={i}>
																		<embed
																			width="330"
																			height="330"
																			name="plugin"
																			src={data.link}
																			type={data.type} />
																		<GridListTileBar
																			classes={{ root: classes.titleBar, subtitle: classes.subtitle }}
																			title={data.original_file_name}
																			subtitle={data.created_date ? getTimeInIST(data.created_date).format(format_display_date) : ''}
																			actionIcon={
																				<>
																					<IconButton aria-label={`download`} className={classes.icon} size="small" onClick={() => openLink(data.link)}>
																						<GetAppIcon />
																					</IconButton>
																				</>
																			}
																		/>
																	</GridListTile>
																)
															})
														}
													</GridList>
												</Grid>
											</Grid>
										</div>
									</AccordionDetails>
								</Accordion>
							</Grid>)
					}
				</Grid>
			</Grid>
			<UpdateLeadDialog
				fullWidth={true}
				maxWidth={'sm'}
				open={openEdit}
				field={field}
				currentValue={value}
				label={label}
				type={type}
				title={`Edit ${label}`}
				close={() => {
					setOpenEdit(false)
					setField(undefined)
					setValue(undefined)
					setLabel(undefined)
					setType(undefined)
				}}
				save={(field, value) => {
					updateLead(field, value)
				}}
			/>
		</Dialog >
	);
}

const customStepIconStyles = makeStyles(theme => ({
	root: {
		color: 'black',
		display: 'flex',
		height: 22,
		alignItems: 'center',
	},
	active: {
		color: '#90caf9',
	},
	circle: {
		width: 25,
		height: 25,
		borderRadius: '50%',
		backgroundColor: '#eaeaf0',
		fontSize: 12,
		color: grey[700]
	},
	completed: {
		color: '#4caf50',
		zIndex: 1,
		fontSize: 18,
		width: theme.spacing(3),
		height: theme.spacing(3),
		borderRadius: '50%',
	},
	cancel: {
		color: '#f44336',
		zIndex: 1,
		fontSize: 18,
		width: theme.spacing(3),
		height: theme.spacing(3),
		borderRadius: '50%',
	},
}));

function CustomStepIcon(props) {
	const classes = customStepIconStyles();
	const { active, completed, error, icon } = props;
	// console.log(props);

	return (
		<div
			className={clsx(classes.root, {
				[classes.active]: active,
			})}
		>
			{
				completed ?
					<CheckCircle className={classes.completed} />
					:
					error ?
						<Cancel className={classes.cancel} />
						:
						<Avatar className={classes.circle}>{icon}</Avatar>
			}
		</div>
	);
}

CustomStepIcon.propTypes = {
	/**
	 * Whether this step is active.
	 */
	active: PropTypes.bool,
	/**
	 * Mark the step as completed. Is passed to child components.
	 */
	completed: PropTypes.bool,
};

ViewLeadDialog.prototype = {
	open: PropTypes.bool.isRequired,
	id: PropTypes.string,
	onClose: PropTypes.func.isRequired
};