import React, { useContext } from 'react';
import { Link } from "react-router-dom";
// import ExpandLess from '@material-ui/icons/ExpandLess';
// import ExpandMore from '@material-ui/icons/ExpandMore';
import HomeIcon from '@material-ui/icons/Home';
import {
  Typography,
  Divider,
  ListItem,
  ListItemIcon,
  ListItemText,
  Drawer,
  IconButton,
  Container,
//   List,
//   Collapse,
  makeStyles,
} from '@material-ui/core';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import classNames from 'classnames';
import { AppContext } from './AppContextProvider';
// import NotificationImportantIcon from '@material-ui/icons/NotificationImportant';
import NotificationsActiveIcon from '@material-ui/icons/NotificationsActive';
import DescriptionIcon from '@material-ui/icons/Description';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import { USERTYPES } from './Constants';

const drawerWidth = 300;
const useStyles = makeStyles((theme) => ({
    drawerOpen: {
        width: drawerWidth,
        transition: theme.transitions.create('width', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.enteringScreen,
        }),
      },
      drawerClose: {
        transition: theme.transitions.create('width', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
        overflowX: 'hidden',
        width: theme.spacing(7) + 1,
        [theme.breakpoints.up('sm')]: {
          width: theme.spacing(9) + 1,
        },
      },
      toolbar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: '0 8px',
        ...theme.mixins.toolbar,
      },
      sectionDesktop: {
        display: 'flex',
        fontSize: 16,
        [theme.breakpoints.up('md')]: {
          display: 'none',
        },
      },
      menuIcon: {
        minWidth: 32
      },
      subMenuTitle: {
        // paddingLeft: 32, 
        paddingTop: 0, 
        paddingBottom: 0
      },
      subMenuItem: {
        color: 'black',
        // paddingTop: 0, 
        // paddingBottom: 0
      },
      nested: {
        paddingLeft: 48, 
        // color: 'black',
        paddingTop: 0, 
        paddingBottom: 0,
      },
      link: {
        textDecoration: 'none !important', 
        color: 'black' 
      },
}));

export default function AppDrawer(props) {

    const classes = useStyles();
    const {state, handleDrawerClose} = useContext(AppContext);

    // const [openDrawer, setOpenDrawer] = React.useState({
    //     accounts: false,
    //     loans: false,
    //     tools: false,
    //     finance: false,
    //     masters: false,
    //     reports: false,
    // })

    // const handleClick = (type) => {
    //     // openDrawer[type] = !openDrawer[type];
    //     setOpenDrawer(openDrawer => ({...openDrawer, [type]: !openDrawer[type]}))
    // }

    return (
        <Drawer
            anchor="left"
            className={classNames(classes.drawer, {
                [classes.drawerOpen]: state.open,
                [classes.drawerClose]: !state.open,
            })}
            classes={{
                paper: classNames({
                    [classes.drawerOpen]: state.open,
                    [classes.drawerClose]: !state.open,
                }),
            }}
            open={state.open}
            onClose={handleDrawerClose}
        >
            <div>
                <div className={classes.toolbar}>
                    <div style={{ float: "left", width: "100%", marginLeft: 2 }}>
                        <img
                            src={process.env.PUBLIC_URL + "/images/icon.png"}
                            alt="icon"
                            height={30}
                        />
                    </div>
                    <IconButton onClick={handleDrawerClose}>
                        {/* <AccountCircleIcon className={classes.accountCircle}/> */}
                        <ChevronLeftIcon />
                    </IconButton>
                </div>
                <div style={{ height: "85vh", overflow: "auto" }}>
                    <Divider />
                    <Link
                        to="/"
                        style={{ textDecoration: "none", color: "black" }}
                        onClick={handleDrawerClose}
                    >
                        <ListItem button key="Dashboard">
                            <ListItemIcon className={classes.menuIcon}>
                                <HomeIcon />
                            </ListItemIcon>
                            <ListItemText
                                primary={<Typography variant="subtitle2">Dashboard</Typography>}
                            />
                        </ListItem>
                    </Link>
                    <Divider />
                    {
                        state.user && ([USERTYPES.CCARE, USERTYPES.ACE, USERTYPES.FRANSPRT, USERTYPES.BRANCH, USERTYPES.SALEHD, USERTYPES.STATEMG].includes(state.user.EmpUtype)) && (
                            <div>
                                <Link to="/leads" style={{ textDecoration: "none", color: "black" }} onClick={handleDrawerClose}>
                                    <ListItem button key="View Leads">
                                        <ListItemIcon className={classes.menuIcon}>
                                            <DescriptionIcon />
                                        </ListItemIcon>
                                        <ListItemText
                                            primary={
                                                <Typography variant="subtitle2">
                                                    View Leads
                                                </Typography>
                                            }
                                        />
                                    </ListItem>
                                </Link>
                                <Divider />
                            </div>
                        )
                    }
                    {
                        state.user && state.user.EmpUtype === USERTYPES.FRANSPRT && (
                            <div>
                                <Link to="/manage/documents" style={{ textDecoration: "none", color: "black" }} onClick={handleDrawerClose}>
                                    <ListItem button key="Manage Documents">
                                        <ListItemIcon className={classes.menuIcon}>
                                            <InsertDriveFileIcon />
                                        </ListItemIcon>
                                        <ListItemText
                                            primary={
                                                <Typography variant="subtitle2">
                                                    Manage Documents
                                                </Typography>
                                            }
                                        />
                                    </ListItem>
                                </Link>
                                <Divider />
                            </div>
                        )
                    }
                    {
                        state.user && state.user.EmpUtype === USERTYPES.FRANSPRT && (
                            <div>
                                <Link to="/manage/notifications" style={{ textDecoration: "none", color: "black" }} onClick={handleDrawerClose}>
                                    <ListItem button key="Manage Notifications">
                                        <ListItemIcon className={classes.menuIcon}>
                                            <NotificationsActiveIcon />
                                        </ListItemIcon>
                                        <ListItemText
                                            primary={
                                                <Typography variant="subtitle2">
                                                    Manage Notifications
                                                </Typography>
                                            }
                                        />
                                    </ListItem>
                                </Link>
                                <Divider />
                            </div>
                        )
                    }
                </div>
            </div>
            <div>
                <Container maxWidth="sm" style={{ position: "absolute", bottom: 0 }}>
                    <Typography variant="subtitle2" style={{ textAlign: "center", fontSize: 10 }}>
                        Version: {global.appVersion}
                        <React.Fragment>
                            <br />
                                Powered by Sysbrew
                        </React.Fragment>
                    </Typography>
                </Container>
            </div>
        </Drawer>
    );
}
