import React from "react";
import { useContext } from "react";
import { AppContext } from "../AppContextProvider";
import { makeStyles, Grid, Paper, Table, TablePagination, TableRow, TableBody, Typography, Divider, Icon, ListItemText, Tooltip, IconButton, Fab, Hidden } from "@material-ui/core";
import { blue, red, grey } from "@material-ui/core/colors";
import { STATUS, TABLE_COUNTS, USERTYPES } from "../Constants";
import { RowCountOptions } from "../Config";
// import EnhancedTableToolbar from "../components/EnhancedTableToolbar";
import EnhancedTableHead from "../components/EnhancedTableHead";
import { toInt, StyledTableCell, BootstrapTooltip, format_display_fancy_notime, getTimeInIST, getStatusBadge } from "../Utils";
import ItemSelection from "../components/ItemSelection";
// import DateRangePickerCustom from "../components/DateRangePickerCustom";
import SearchBar from "../components/SearchBar";
import AddIcon from '@material-ui/icons/Add';
import queryString from 'query-string';
import { LeadService } from "../Services/LeadService";

const useStyles = makeStyles(theme => ({
    section: {
        width: '100%',
        height: '100%',
        marginTop: theme.spacing(1),
        paddingTop : theme.spacing(1),
        paddingBottom: theme.spacing(1)
    },
    qtyField: {
        width: "40%",
        position: "relative",
        [theme.breakpoints.up('md')]: {
            right: '100px',
            width: "10%",
            marginRight: 10
        },
        right: "22px"
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(4),
        outline: 'none',
        textAlign: 'center',
        color: theme.palette.text.secondary,
        marginBottom : '20px',
    },
    grid: {
        [theme.breakpoints.up('md')]: {
            marginTop: '10px',
        },
        marginTop: '50px',
        marginBottom: '5px'
    },
    icon: {
        padding: '0px',
        [theme.breakpoints.up('md')]: {
            padding: '12px',
        },
    },
    tablecell: {
        fontSize: '16pt',
        padding: theme.spacing(4)
    },
    container: {
        paddingTop : theme.spacing(1)
    },
    redAvatar: {
        margin: 10,
        color: '#fff',
        backgroundColor: red[500],
    },
    blueAvatar: {
        margin: 10,
        color: '#fff',
        backgroundColor: blue[500],
    },
    instructions: {
        marginTop: theme.spacing(3),
        marginLeft: theme.spacing(3),
        marginBottom: theme.spacing(1),
    },
    button: {
        marginTop: theme.spacing(1),
        // height: theme.spacing(7),
        float: 'right',
        marginLeft: theme.spacing(2),
        [theme.breakpoints.down('md')]: {
            float: 'inherit',
            marginLeft: theme.spacing(1)
        },
        marginRight: theme.spacing(4),
        
    }, 
    fab: {
        margin: theme.spacing(1),
        position: 'fixed',
        bottom: theme.spacing(2),
        right: theme.spacing(2),
    },
    tableWrapper: {
        maxHeight: 407,
        overflow: 'auto',
    },
    list: {
        width: '100%',
        height: 250,
        overflow: "auto"
    },
    summary: {
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2)
    },
    title: {
        fontSize: 18,
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
    },
    subtitle: {
        fontSize:18,
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
    },
    selectRoot: {
        fontSize: 12,
        [theme.breakpoints.down('md')]: {
            marginLeft: 0,
            marginRight: 0
        },
    },
}));

export default function ViewLeads(props){

    const {state, showSnackBar, showConfirmDialog, closeConfirmDialog, setTitle, setLinearLoading, showProgressDialog, closeProgressDialog, openNewLead, openLead} = useContext(AppContext);
    const classes = useStyles();
    const [selectedStatuses, setSelectedStatuses] = React.useState([]);
    const [filter, setFilter] = React.useState('');
    const statuses = [{name: 'Started', value: 1}, {name: 'Finished', value: 2}, {name: 'Rejected', value: -1} ]
    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState('full_name');
    const [currentRows, setCurrentRows] = React.useState([]);
    const [generate, setGenerate] = React.useState(true);
    const [loadedMaster, setLoadedMaster] = React.useState(false);
    const columns = [
        { id: 'index', align: 'left', numeric: false, disablePadding: false, label: 'Sl.No.', sortable: false },
        { id: 'full_name', align: 'justify', numeric: false, disablePadding: false, label: 'Name', sortable: true },
        { id: 'branch', align: 'center', numeric: false, disablePadding: true, label: 'Branch', sortable: true },
        { id: 'email', align: 'center', numeric: false, disablePadding: true, label: 'Email', sortable: true },
        { id: 'mobile', align: 'center', numeric: false, disablePadding: true, label: 'Mobile', sortable: true },
        { id: 'pan', align: 'center', numeric: false, disablePadding: true, label: 'PAN', sortable: true },
        { id: 'current_task', align: 'center', numeric: false, disablePadding: true, label: 'Current Task', sortable: false },
        { id: 'created_date', align: 'center', numeric: false, disablePadding: true, label: 'Created', sortable: true },
        { id: 'status', align: 'center', numeric: false, disablePadding: false, label: 'Status', sortable: true },
        { id: 'action', align: 'right', numeric: true, disablePadding: false, label: '', sortable: false },
    ];

    //Pagination
    const [totalItems, setTotalItems] = React.useState(0);
    const [numItemsPerPage, setNumItemsPerPage] = React.useState(localStorage.getItem(TABLE_COUNTS.leads_tab_count) ? toInt(localStorage.getItem(TABLE_COUNTS.leads_tab_count)) : RowCountOptions[0]);
    const [currentPageNumber, setCurrentPageNumber] = React.useState(0);
    const [fetchPage, setFetchPage] = React.useState(false);
    const userFilter = [USERTYPES.BRANCH, USERTYPES.STATEMG, USERTYPES.SALEHD].includes(state.user.EmpUtype) ? state.user.EmpUcode.toLowerCase() : null;

    //Dialog
    // const [openChangeStatus, setOpenChangeStatus] = React.useState(false);
    // const [selectedProperty, setSelectedProperty] = React.useState({});

    const handleChangeRowsPerPage = event => {
        setNumItemsPerPage(event.target.value);
        setCurrentPageNumber(0);
        setFetchPage(true);
        localStorage.setItem(TABLE_COUNTS.leads_tab_count, event.target.value);
    }

    const handleChangePage = (event, page) => {
        if(currentPageNumber > page){
            fetchRow(currentPageNumber - 1);
        } else {
            fetchRow(currentPageNumber + 1);
        }
    };

    const fetchRow = (newCurrentPage) => {
        setCurrentPageNumber(newCurrentPage);
        setFetchPage(true);
    }

    React.useEffect(() => {
        setTitle('View Leads');
        if(props.location && props.location.search){
            let qString = queryString.parse(props.location.search);
            if(qString.status){
                let status = statuses.find(s => s.value === qString.status);
                if(status){
                    setSelectedStatuses([toInt(status)]);
                }
			}
		}
        setLoadedMaster(true);
        return () => {
            setTitle('');
        }
    }, [true]);

    React.useEffect(() => {
        // console.log(generate, loadedMaster)
        if(generate && loadedMaster){
            setCurrentRows([]);
            setLinearLoading(true);
            setCurrentPageNumber(0);
            setTotalItems(0);
            // setToolTitle(undefined);
            showProgressDialog();
            let statuses = selectedStatuses && selectedStatuses.length > 0 ? selectedStatuses.map(s => s.value).join(',') : null;
            let fc = filter && filter.length > 0 ? filter : '';
            LeadService.getLeadCount(statuses, userFilter, fc)
            .then(data => {
                // console.log(data);
                setTotalItems(toInt(data.count));
                setFetchPage(true);
                if(toInt(data.count) === 0){
                    setCurrentRows([]);
                }
                setLinearLoading(false);
            })
            .catch(error => {
                console.log(error);
                setLinearLoading(false);
            });
            setGenerate(false);
        }
    }, [generate, loadedMaster]);

    const openDialog = (id) => {
        openLead(id, (shouldRefresh) => {
            if(shouldRefresh){
                setGenerate(true);
            }
        });
    }

    const handleEdit = (id) => {
        console.log(id);
        openNewLead(id, () => {
            console.log('Refresh');
            setGenerate(true)
        });
    }

	const remove = (id) => {
		showProgressDialog('Removing Lead...');
		LeadService.removeLead(id)
		.then(data => {
            showSnackBar('Lead Removed', 'info');
            closeProgressDialog();
            setGenerate(true);
		})
		.catch(error => {
			console.log(error);
			showConfirmDialog('Something went wrong. Please try again.', undefined, () => {
				closeConfirmDialog();
			});
			closeProgressDialog();
		})
    }
    
    const handleRemove = (id) => {
        console.log(id);
        showConfirmDialog(`Confirm Removal of Lead?`, undefined, () => {
            closeConfirmDialog();
            remove(id);
        });
    }

    React.useEffect(() => {
        let rows = [];
        if(fetchPage) {
            setLinearLoading(true);
            let statuses = selectedStatuses && selectedStatuses.length > 0 ? selectedStatuses.map(s => s.value).join(',') : null;
            let fc = filter && filter.length > 0 ? filter : '';
            LeadService.getLeadList(statuses, userFilter, fc, orderBy, order, currentPageNumber * numItemsPerPage, numItemsPerPage)
            .then((data) => {
                // console.log(data);
                if(data){
                    data.forEach((element, index) => {
                        let days = element.created_date ? getTimeInIST(element.created_date).fromNow() : 0;
                        let tat = getTimeInIST(element.modified_date).diff(getTimeInIST(element.created_date), 'days');
                        rows.push(
                            <TableRow 
                                key={index} 
                                hover
                                style={{cursor: 'pointer'}} 
                                onClick={(e) => {
                                    e.stopPropagation();
                                    openDialog(element.id);
                                }}>
                                <StyledTableCell scope="row" align="center" padding='none' style={{width: 20}}>
                                    {index + 1 + (currentPageNumber * numItemsPerPage)}
                                </StyledTableCell>
                                <StyledTableCell scope="row" align="justify" padding='none'>
                                    <ListItemText
                                        primary={element.full_name || ''}
                                        secondary={<Typography>
														{element.off_add1 || ''}{`, ${element.off_add1}` || ''}<br/>
														{element.off_add3 ? <span>{`${element.off_add3}` || ''}<br/></span> : '' }
														{element.off_city || ''},<br/>
														{element.off_state || ''},<br/>
														{element.off_pincode || ''}
													</Typography>
                                        }
                                        //secondaryTypographyProps={{style: {color: 'red'}}}
                                    />
                                </StyledTableCell>
                                <StyledTableCell scope="row" align="justify" padding='none'>
                                    {element.branch}
                                </StyledTableCell>
                                <StyledTableCell scope="row" align="center" padding='none'>
                                    {element.email || '-'}
                                </StyledTableCell>
                                <StyledTableCell scope="row" align="center" padding='none' style={{paddingRight: 16}}>
                                    {element.mobile || '-'}
                                </StyledTableCell>
                                <StyledTableCell scope="row" align="center" padding='none' style={{paddingRight: 16}}>
                                    {element.pan || '-'}
                                </StyledTableCell>
                                <StyledTableCell scope="row" align="center" padding='none'>
                                    <ListItemText
                                        primary={element.current_step.name || '-'}
                                        secondary={element.current_step ? `(Started ${getTimeInIST(element.current_step.created_date).fromNow()})`: null}
                                        secondaryTypographyProps={{style: {color: grey[600]}}}
                                    />
                                </StyledTableCell>
                                <StyledTableCell scope="row" align="center" padding='none'>
                                    <ListItemText
                                        primary={element.created_date ? getTimeInIST(element.created_date).format(format_display_fancy_notime) : '-'}
                                        secondary={days || ''}
                                        secondaryTypographyProps={{style: {color: grey[600]}}}
                                    />
                                </StyledTableCell>
                                <StyledTableCell scope="row" align="center" padding='none' style={{paddingRight: 16}}>
                                    {getStatusBadge(element.status)}
                                    {
                                        element.status === STATUS.CLOSED && (
                                            <ListItemText
                                                primary={element.modified_date ? getTimeInIST(element.modified_date).format(format_display_fancy_notime) : '-'}
                                                secondary={`TAT: ${tat} days` || ''}
                                                secondaryTypographyProps={{style: {color: grey[600]}}}
                                            />
                                        )
                                    }
                                </StyledTableCell>
                                <StyledTableCell scope="row" align="right" padding='none' style={{paddingRight: 16}}>
                                    {/* <BootstrapTooltip arrow title="Edit">
                                        <Icon 
                                            fontSize="small" 
                                            style={{marginTop: 8, marginRight: 8}} 
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                handleEdit(element.id)
                                            }}>edit</Icon>
                                    </BootstrapTooltip> */}
                                    {
                                        element.steps_approved === 0 && (
                                            <BootstrapTooltip arrow title="Remove">
                                                <Icon 
                                                    fontSize="small" 
                                                    style={{marginTop: 8, marginRight: 16}} 
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        handleRemove(element.id)
                                                    }}>delete</Icon>
                                            </BootstrapTooltip>
                                        )
                                    }
                                </StyledTableCell>
                            </TableRow>
                        );
                    });
                }
                setCurrentRows(rows);
                setLinearLoading(false);
                closeProgressDialog();
            })
            .catch(error => {
                console.log(error);
                setLinearLoading(false);
                closeProgressDialog();
            });
        }
        setFetchPage(false);
    }, [fetchPage]);

    const sortBy = (event, property) => {
        let o = 'desc';
        if (orderBy === property && order === 'desc') {
            o = 'asc';
        }
        setOrder(o);
        setOrderBy(property);
        setGenerate(true);
    }

    // const changeStatus = (property) => {
    //     setOpenChangeStatus(true);
    //     setSelectedProperty(property);
    // }

    return (
        <div className="Home">
            <div className="lander">
                <Grid container direction="row" spacing={1} justify="center">
                    <Grid item xl={11} lg={12} xs={12}>
                        <Paper className={classes.section}>
                            <Grid container spacin={1} alignItems="center">
                                <Grid item container xs={12} lg={12} alignItems="center" justify="space-between">
                                    {/* <Grid item xs={12} lg={2}>
                                        <ItemSelection 
                                            multiple 
                                            clearable={true} 
                                            label='Type' 
                                            value={selectedType} 
                                            optionLabel='name' 
                                            options={types} 
                                            selected={setSelectedType}/>
                                    </Grid> */}
                                    <Grid item xs={12} lg={2}>
                                        <ItemSelection 
                                            multiple 
                                            clearable={true} 
                                            label='Status' 
                                            value={selectedStatuses} 
                                            optionLabel='name' 
                                            options={statuses} 
                                            selected={(s) => {
                                                setSelectedStatuses(s);
                                                setGenerate(true);
                                            }}/>
                                    </Grid>
                                    <Hidden mdDown><Grid item xs={12} lg={5}/></Hidden>
                                    <Grid item xs={8} lg={3}>
                                        {
                                            loadedMaster && (
                                                <div style={{float: 'right', marginTop: 8, marginLeft:8, marginRight: 8}}>
                                                    <SearchBar 
                                                        hint="Search..."
                                                        searchText={filter}
                                                        onTextChange={(e) => {
                                                        setFilter(e.target.value);
                                                        setGenerate(true);
                                                    }}/>
                                                </div>
                                            )
                                        }
                                    </Grid>
                                    <Grid item>
                                        <Tooltip title="Refresh">
                                            <IconButton size="medium" style={{marginTop: 8}} aria-label="Refresh" color="primary" onClick={() => {!generate && setGenerate(true)}}>
                                                <Icon>refresh</Icon>
                                            </IconButton>
                                        </Tooltip>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} lg={12}>
                                    <Divider style={{marginBottom: 0, marginTop: 10}}/>
                                </Grid>
                                <Grid item xs={12} lg={12}>
                                    <div style={{minHeight: 100, overflowY: 'auto', display: 'block'}}>
                                        <Table className={classes.table} stickyHeader>
                                            <EnhancedTableHead
                                                order={order}
                                                orderBy={orderBy}
                                                onRequestSort={sortBy}
                                                rows={columns} />
                                            <TableBody>
                                                {currentRows}
                                            </TableBody>
                                        </Table>
                                    </div>
                                    {
                                        totalItems === 0 && 
                                            <Typography color="error" variant="subtitle2">No Leads to list</Typography>
                                    }
                                    <TablePagination
                                        rowsPerPageOptions={RowCountOptions}
                                        component="div"
                                        count={totalItems}
                                        rowsPerPage={numItemsPerPage}
                                        page={currentPageNumber}
                                        backIconButtonProps={{
                                            'aria-label': 'Previous Page',
                                        }}
                                        nextIconButtonProps={{
                                            'aria-label': 'Next Page',
                                        }}
                                        onChangePage={handleChangePage}
                                        onChangeRowsPerPage={handleChangeRowsPerPage}
                                        classes={{ selectRoot : classes.selectRoot}}/>
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>
                </Grid>
            </div>
            {/* <ChangeStatus 
                open={openChangeStatus} 
                id={selectedProperty.id} 
                subtitle={selectedProperty.name}
                fullWidth={true}
                maxWidth={'xs'} 
                refresh={() => {
                    setGenerate(true);
                }} 
                handleClose={() => {
                    setOpenChangeStatus(false);
                    setSelectedProperty({});
                }}/> */}
            {
                !state.linearLoading && (
                    <BootstrapTooltip arrow title="Add New Lead">
                        <Fab variant="extended" onClick={() => handleEdit(null)} color="primary" aria-label="Add" className={classes.fab}>
                            <AddIcon />
                            Add New Lead
                        </Fab>
                    </BootstrapTooltip>
                )
            }
        </div>
    )
}