import Config from '../Config';
import {RestService} from './RestService';

export const LeadService = {
    getLeadCount: async function(status = null, branch = null, filter = ''){
        return RestService.get(`${Config.list_leads_url}?count=true&filter=${filter}${status ? `&status=${status}` : ''}${branch ? `&branch=${branch}` : ''}`, {})
        .then(response => response.json());
    },
    getLeadList: async function(status = null, branch = null, filter = '', sort = null, direction = null, start = 0, offset = 20){
        return RestService.get(`${Config.list_leads_url}?filter=${filter}${status ? `&status=${status}` : ''}${branch ? `&branch=${branch}` : ''}&from=${start}&offset=${offset}${sort ? `&sort=${sort}` : ''}${direction ? `&direction=${direction}` : ''}`, {})
        .then(response => response.json());
    },
    searchLead: async function(filter = '', start = 0, offset = 20){
        return RestService.get(`${Config.search_url}?filter=${filter}&from=${start}&offset=${offset}`, {})
        .then(response => response.json());
    },
    getLeadById: async function(id = "", onlyForm = false) {
        return RestService.get(`${Config.get_lead_detail_url.replace("{id}", id)}?onlyForm=${onlyForm}`, {})
        .then(response => response.json());
    },
    removeLead: async function(id, headers = {}) {
        return RestService.delete(Config.get_lead_detail_url.replace('{id}', id), headers)
        .then(response => response.json());
    },
    getSubSteps: async function(step = "") {
        return RestService.get(Config.get_sub_steps_url.replace("{step}", step), {})
        .then(response => response.json());
    },
    createLead: async function(body, headers = {}) {
        return RestService.postFormData(Config.save_lead_url, headers, body)
        .then(response => response.json());
    },
    saveStep: async function(lead, body, headers = {}) {
        return RestService.post(Config.save_step_url.replace("{lead}", lead), headers, body)
        .then(response => response.json());
    },
    updateLead: async function(lead, body, headers = {}) {
        return RestService.post(Config.update_lead_url.replace("{lead}", lead), headers, body);
    },
    resendEmail: async function(lead, step, headers = {}) {
        return RestService.get(Config.resend_email_url.replace("{lead}", lead).replace("{step}", step), headers)
        .then(response => response.json());
    },
    resendSMS: async function(lead, step, headers = {}) {
        return RestService.get(Config.resend_sms_url.replace("{lead}", lead).replace("{step}", step), headers)
        .then(response => response.json());
    },
    getLeadStepDocuments: async function(lead = null, step = null){
        return RestService.get(`${Config.lead_step_documents_url.replace('{lead}', lead)}${step ? `?step=${step}` : ''}`, {})
        .then(response => response.json());
    },
    addLeadStepDocument: async function(lead, step, body, headers = {}) {
        return RestService.postFormData(Config.add_lead_step_documents_url.replace('{lead}', lead).replace('{step}', step), headers, body)
        .then(response => response.json());
    },
    removeDocument: async function(id, lead, step, headers = {}) {
        return RestService.delete(Config.remove_lead_step_documents_url.replace('{id}', id).replace('{lead}', lead).replace('{step}', step), headers);
    },
    
};