const host = process.env.NODE_ENV === 'production' ? "https://api.franchisee.acumengroup.in" : "https://localhost:9000";

const Config = {

    authenticate_url: `${host}/authenticate`,
    authenticate_token_url: `${host}/authenticate/token`,
    save_lead_url: `${host}/v2/lead`,
    save_step_url: `${host}/lead/step/{lead}`,
    update_lead_url: `${host}/lead/field/{lead}`,
    list_leads_url: `${host}/lead/list`,
    search_url: `${host}/search`,
    get_lead_detail_url: `${host}/lead/{id}`,
    pincode_url: `${host}/pincode`,
    config_url: `${host}/app/config`,
    step_config_url: `${host}/step/config`,
    resend_email_url: `${host}/lead/step/resend/email/{lead}/{step}`,
    resend_sms_url: `${host}/lead/step/resend/sms/{lead}/{step}`,
    get_sub_steps_url: `${host}/step/substep/{step}`,
    form_template_url: `${host}/form/{type}`,
    save_form_data_url: `${host}/lead/form`,
    retrieve_form_data_url: `${host}/lead/form/{lead}/{type}`,
    check_form_status_url: `${host}/check/lead/form/{lead}/{type}`,
    document_url: `${host}/document/{type}`,
    document_only_url: `${host}/document`,
    remove_document_url: `${host}/document/{type}/{docId}`,
    lead_step_documents_url: `${host}/lead/step/document/{lead}`,
    add_lead_step_documents_url: `${host}/lead/step/document/{lead}/{step}`,
    remove_lead_step_documents_url: `${host}/lead/step/document/{lead}/{step}/{id}`,
    ace_users_url: `${host}/ace/users`,
    charges_url: `${host}/charges`,
    
    REFRESH_INTERVAL: 300000
}

export default Config;

export const RowCountOptions = [10, 25, 50, 100];