import React from "react";
import { useContext } from "react";
import { AppContext } from "../AppContextProvider";
import { makeStyles, Grid, Paper, IconButton, Typography, GridList, GridListTile, GridListTileBar, Fab, FormControl, TextField, Button } from "@material-ui/core";
import { blue, red, grey } from "@material-ui/core/colors";
import {  getTimeInIST, format_display_date, BootstrapTooltip, openLink, HtmlTooltip } from "../Utils";
import { DocumentService } from "../Services/DocumentService";
import DeleteIcon from '@material-ui/icons/Delete';
import GetAppIcon from '@material-ui/icons/GetApp';
import AddIcon from '@material-ui/icons/Add';
import MyDialog from "../components/MyDialog";
import CloudUploadIcon from '@material-ui/icons/CloudUpload';

const useStyles = makeStyles(theme => ({
    section: {
        width: '100%',
        height: '100%',
        marginTop: theme.spacing(1),
        paddingTop : theme.spacing(1),
        paddingBottom: theme.spacing(1),
        paddingLeft: 16,
        paddingRight: 16
    },
    fab: {
        margin: theme.spacing(1),
        position: 'fixed',
        bottom: theme.spacing(2),
        right: theme.spacing(2),
    },
    qtyField: {
        width: "40%",
        position: "relative",
        [theme.breakpoints.up('md')]: {
            right: '100px',
            width: "10%",
            marginRight: 10
        },
        right: "22px"
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(4),
        outline: 'none',
        textAlign: 'center',
        color: theme.palette.text.secondary,
        marginBottom : '20px',
    },
    grid: {
        [theme.breakpoints.up('md')]: {
            marginTop: '10px',
        },
        marginTop: '50px',
        marginBottom: '5px'
    },
    tablecell: {
        fontSize: '16pt',
        padding: theme.spacing(4)
    },
    container: {
        paddingTop : theme.spacing(1)
    },
    redAvatar: {
        margin: 10,
        color: '#fff',
        backgroundColor: red[500],
    },
    blueAvatar: {
        margin: 10,
        color: '#fff',
        backgroundColor: blue[500],
    },
    instructions: {
        marginTop: theme.spacing(3),
        marginLeft: theme.spacing(3),
        marginBottom: theme.spacing(1),
    },
    button: {
        marginTop: theme.spacing(1),
        // height: theme.spacing(7),
        float: 'right',
        marginLeft: theme.spacing(2),
        [theme.breakpoints.down('md')]: {
            float: 'inherit',
            marginLeft: theme.spacing(1)
        },
        marginRight: theme.spacing(4),
        
    }, 
    tableWrapper: {
        maxHeight: 407,
        overflow: 'auto',
    },
    list: {
        width: '100%',
        height: 250,
        overflow: "auto"
    },
    summary: {
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
        paddingLeft: theme.spacing(2),
        width: '40vw',
        [theme.breakpoints.down('md')]: {
           width: '100vw'
        },
    },
    title: {
        fontSize: 12,
        textAlign: 'left',
    },
    subtitle: {
        fontSize: 12,
        textAlign: 'left'
    },
    icon: {
        color: grey[50],
        padding: 8
    },
    selectRoot: {
        fontSize: 12,
        [theme.breakpoints.down('md')]: {
            marginLeft: 0,
            marginRight: 0
        },
    },
    gridList: {
        margin: 16,
        width: '100%'
    },
    titleBar: {
        // background:
        //   'linear-gradient(to bottom, rgba(0,0,0,0.7) 0%, ' +
        //   'rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)',
    },
    truncate: {
		maxWidth: 200,
		whiteSpace: 'nowrap',
		overflow: 'hidden',
		textOverflow: 'ellipsis',
		textAlign: 'left'
	}
}));

export default function DocumentPage(props){

    const {mobileView, state, setTitle, setLinearLoading, showProgressDialog, closeProgressDialog, showSnackBar, showConfirmDialog, closeConfirmDialog} = useContext(AppContext);
    const classes = useStyles();
    const [data, setData] = React.useState([]);
    const [open, setOpen] = React.useState(false);
    const [fileUploaded, setFileUploaded] = React.useState(null);
    const [documentType, setDocumentType] = React.useState(null);

    React.useEffect(() => {
        setTitle('Manage Documents');
        // console.log(state);
        return () => {
            setTitle('');
        }
    }, [true]);

    React.useEffect(() => {
        fetchData();
    }, []);
    
    const fetchData = () => {
        setLinearLoading(true);
        showProgressDialog();
        DocumentService.getAll()
        .then(data => {
            console.log(data);
            if(data){
                setData(data);
            }
            setLinearLoading(false);
            closeProgressDialog();
        })
        .catch(error => {
            console.log(error);
            setLinearLoading(false);
            closeProgressDialog();
        });
    }

    const handleClose = () => {
        setOpen(false);
        setFileUploaded(null);
        setDocumentType(null)
    }

    const hiddenFileInput = React.useRef(null);

    const handleClick = () => {
        hiddenFileInput.current.click();
	}
	
	const change = (event) => {
        const fileUploaded = event.target.files[0];
        setFileUploaded(fileUploaded);
    }

    const upload = () => {
        const formData = new FormData();
		formData.append('file', fileUploaded || null);
		showProgressDialog('Uploading...');
		// setLoading(true);
		DocumentService.addDocument(documentType, formData)
		.then(data => {
            closeProgressDialog();
            handleClose();
			showSnackBar('Uploaded document', 'info', 1000);
			fetchData();
		})
		.catch(error => {
			console.log(error);
			showSnackBar('Something went wrong. Please try again.', 'error', 1000);
		})
    }
    
    const remove = (id) => {
        console.log(id);
		showConfirmDialog('Are you sure you want to remove file?', undefined, () => {
			closeConfirmDialog();
			DocumentService.removeDocument(id, documentType)
			.then(() => {
				showSnackBar('Removed document', 'info', 1000);
				fetchData();
			})
			.catch(error => {
				console.log(error);
				showSnackBar('Something went wrong. Please try again.', 'error', 1000);
			})
		});
    }
	

    return (
        <div className="Home">
            <div className="lander">
                <Grid container direction="row" spacing={1} justify="center">
                    <Grid item xl={10} lg={10} xs={12}>
                        <Paper className={classes.section}>
                            <Grid container spacin={1} alignItems="center">                                
                                <Grid item xs={12}>
                                    <GridList cellHeight={330} className={classes.gridList} cols={mobileView ? 1: 4}>
                                    {
                                        data.map((data, i) => 
                                            <GridListTile key={i}>
                                                <embed 
                                                    width="300" 
                                                    height="330" 
                                                    name="plugin" 
                                                    src={data.link}
                                                    type={data.type}/>
                                                <GridListTileBar
                                                    classes={{root: classes.titleBar, title : classes.title, subtitle: classes.subtitle}}
                                                    title={
                                                            <HtmlTooltip arrow title={data.original_file_name}>
                                                                <div>
                                                                    <div className={classes.truncate}>{data.document_type}</div>
                                                                    <div className={classes.truncate}>{data.original_file_name}</div>
                                                                </div>
                                                            </HtmlTooltip>}
                                                    subtitle={data.created_date ? getTimeInIST(data.created_date).format(format_display_date) : ''}
                                                    actionIcon={
                                                        <>
                                                            <IconButton aria-label={`download`} className={classes.icon} size="small" onClick={() => openLink(data.link)}>
                                                                <GetAppIcon />
                                                            </IconButton>
                                                            <IconButton aria-label={`remove`} className={classes.icon} size="small" onClick={() => {remove(data.id)}}>
                                                                <DeleteIcon />
                                                            </IconButton>
                                                        </>
                                                    }
                                                />
                                            </GridListTile>
                                        )
                                    }
                                    </GridList>
                                    {
                                        !state.linearLoading && data.length === 0 && (
                                            <div style={{height: 100, paddingTop: 40, cursor: 'pointer'}}  onClick={() => setOpen(true)}>
                                                <Typography color="primary" variant="subtitle2">No Documents Available. Click here to get Started.</Typography>
                                            </div>
                                        )
                                    }
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>
                </Grid>
            </div>
            <BootstrapTooltip arrow title="Upload New Document">
                <Fab color="primary" aria-label="Add" className={classes.fab} onClick={() => setOpen(true)}>
                    <AddIcon />
                </Fab>
            </BootstrapTooltip>
            <MyDialog
                maxWidth={false}
                fullWidth={false}
                content={
                    <div style={{paddingLeft: 16, paddingRight: 16, paddingTop: 16}}>
                        <Grid container spacin={2} alignItems="center" justify="flex-start">
                            <Grid item xs={12} lg={12}>
                                <FormControl variant="outlined" fullWidth>
                                    <TextField
                                        label="Document Type"
                                        id="outlined-adornment-pan"
                                        value={documentType || ''}
                                        required
                                        onChange={(e) => setDocumentType(e.target.value)}
                                        aria-describedby="outlined-weight-helper-text"
                                        variant="outlined"
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} lg={6}>
                                <Button fullWidth variant="contained" onClick={handleClick} startIcon={<CloudUploadIcon />} style={{marginTop: 8}}>Select</Button>
                                <input 
                                    accept="image/*,application/pdf, application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint, text/plain" 
                                    type="file" 
                                    onChange={change} 
                                    onClick={(event) =>  event.target.value = null} ref={hiddenFileInput} 
                                    style={{display:'none'}} />
                            </Grid>
                            <Grid item xs={12} lg={2}>
                                <Button disabled={!documentType || !fileUploaded} color="primary" variant="contained" onClick={upload} style={{marginTop: 8, marginLeft: 8}}>Save</Button>
                            </Grid>
                        </Grid>
                        
                    </div>
                }
                title={'Upload Document'}
                subtitle={''}
                open={open}
                close={() => {
                    handleClose();
                }}/>
        </div>
    )
}