import React, { useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import Divider from '@material-ui/core/Divider';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import PropTypes from 'prop-types';
import { Grid, Paper, CardHeader, FormControl, TextField, FormControlLabel, Checkbox, RadioGroup, Radio, FormHelperText } from '@material-ui/core';
import { red } from '@material-ui/core/colors';
import { AppContext } from '../AppContextProvider';
import { LeadService } from '../Services/LeadService';
import { FormService } from '../Services/FormService';
import { FORM_OPTIONS } from '../Constants';
import GetAppIcon from '@material-ui/icons/GetApp';
import { useReactToPrint } from 'react-to-print';

const useStyles = makeStyles((theme) => ({
	appBar: {
		position: 'relative',
	},
	title: {
		marginLeft: theme.spacing(2),
		flex: 1,
		color: theme.palette.white
	},
	subtitle: {
		// marginLeft: theme.spacing(2),
		flex: 1,
		fontWeight: 500
		// color: theme.palette.white
	},
	page: {
		height: '100%',
		backgroundColor: theme.palette.divider,
		overflow: 'auto',
		paddingTop: theme.spacing(3),
	},
	section: {
		marginLeft: 16,
		marginRight: 16,
		minHeight: 200,
		marginTop: theme.spacing(2)
	},
	avatar: {
		backgroundColor: red[500],
	},
	required: {
		color: red[500],
	},
	image: {
		margin: 12,
		position: 'relative',
		height: 200,
		width: '90% !important',
		[theme.breakpoints.down('xs')]: {
			width: '100% !important', // Overrides inline-style
			height: 100,
		},
		'&:hover,  &Mui-focusVisible': {
			zIndex: 1,
			'& $imageBackdrop': {
				opacity: 0.7,
			},
			'& $imageMarked': {
				opacity: 0,
			},
			'& $imageTitle': {
				border: '4px solid currentColor',
			},
		},
	},
	imageBackdrop: {
		borderRadius: 8,
		position: 'absolute',
		left: 0,
		right: 0,
		top: 0,
		bottom: 0,
		backgroundColor: theme.palette.common.black,
		opacity: 0.3,
		transition: theme.transitions.create('opacity'),
	},
	imageTitle: {
		position: 'relative',
		padding: `${theme.spacing(0)}px ${theme.spacing(4)}px ${theme.spacing(0)}px`,
	},
	imageMarked: {
		height: 3,
		width: 18,
		backgroundColor: theme.palette.common.white,
		position: 'absolute',
		bottom: -2,
		left: 'calc(50% - 9px)',
		transition: theme.transitions.create('opacity'),
	},
	imageButton: {
		position: 'absolute',
		left: 0,
		right: 0,
		top: 0,
		bottom: 0,
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		color: theme.palette.common.white,
	},
	imageButtonBottom: {
		position: 'absolute',
		left: 0,
		right: 0,
		// top: 0,
        // bottom: 0,
        bottom: 10,
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		color: theme.palette.common.white,
	},
	formAutoClass: {
		zIndex: 3,
	},
	backdrop: {
		zIndex: theme.zIndex.drawer + 1,
		color: '#fff',
	},
	card: {
		margin: 8
	},
	truncate: {
		maxWidth: 200,
		whiteSpace: 'nowrap',
		overflow: 'hidden',
		textOverflow: 'ellipsis',
		textAlign: 'left'
	},
	cardHeader: {
		backgroundColor: theme.palette.primary.main,
	}
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const fieldMargin = "dense";

export default function FillFormDialog(props) {
	const {showProgressDialog, closeProgressDialog, showConfirmDialog, closeConfirmDialog, showConfirmDialog1D, closeConfirmDialog1D} = useContext(AppContext);
	const {id, type} = props;
	const classes = useStyles();
	const [open, setOpen] = React.useState(false);
	const [form, setForm] = React.useState({});
	const [lead, setLead] = React.useState({});
	const [errorList, setErrorList] = React.useState({});
	const [formData, setFormData] = React.useState([]);
	const [disabled, setDisabled] = React.useState(false);
	const componentRef = React.useRef();

	React.useEffect(() => {
		setOpen(props.open);
		if(props.open){
			setForm({});
			fetchData();
		}
		return () => {
			setForm({});
			setErrorList({});
			setOpen(false);
		}
	},[props.open]);

	const fetchData = () => {

		if(id && type){
			showProgressDialog();
			Promise.all([FormService.getFormTemplate(type), LeadService.getLeadById(id, true), FormService.retrieveLeadFormData(id, type)])
			.then(datas => {
				console.log(datas);
				let errorList = {};
				if(datas[0]){
					setForm(datas[0]);
					datas[0].segments.forEach(s => {
						s.rows.forEach(r => {
							if(r.field_type !== FORM_OPTIONS.group){
								if(r.required === 1){
									errorList[r.id] = true;
								}
							} else if(r.field_type === FORM_OPTIONS.group){
								r.options.forEach(t => {
									if(t.required === 1){
										errorList[t.id] = true;
									}
								})
							}
						})
					});
				}
				if(datas[1]){
					setLead(datas[1]);
				}
				let fd = datas[2];
				if(fd && fd.length > 0){
					setDisabled(true);
					setFormData(fd);
					Object.keys(errorList).forEach(id => {
						// console.log(fd, id, fd.find(f => parseInt(f.id) === id));
						if(fd.find(f => f.id === parseInt(id))){
							errorList[id] = false;
						}
					})
				}
				setErrorList(errorList);
				closeProgressDialog();
			})
			.catch(error => {
				console.log(error);
				showConfirmDialog1D('Something went wrong. Please try again.', undefined, () => {
					handleClose(false);
					closeConfirmDialog1D();
				}, 'OK');
				closeProgressDialog();
			});
		}
	}

	// const exportFile = () => {
	// 	// var doc = new jsPDF();
	// 	// var source = window.document.getElementById("form-block");
	// 	// doc.fromHTML(source);
	// 	// doc.save(`${lead.full_name}.pdf`);
	// 	useReactToPrint
	// }
	const exportFile = useReactToPrint({
		content: () => componentRef.current,
	  });

	const handleClose = (showConfirm = false, shouldRefresh = false) => {
		if(showConfirm && !disabled){
			showConfirmDialog('Are you sure you want to close. Any changes made will be lost?', undefined, () => {
				closeConfirmDialog();
				close(shouldRefresh);
			});
		} else {
			close(shouldRefresh);
		}
		
	};

	const close = (shouldRefresh = false) => {
		setForm({});
		setErrorList({});
		setDisabled(false);
		setFormData([])
		props.onClose(shouldRefresh);
		setOpen(false);
	}

	const handleChange = field => e => {
		let value = e.target.value;
		if(e.target.type === 'checkbox'){
			value = e.target.checked ? 1: 0;
		} else {
			setErrorList(e => ({...e, [field] : !value}));
		}
		setFormData(formData => ([...formData.filter(f => f.id !== field), {id: field, value: value}]));
		
	}

	const saveForm = () => {
		if(isValid()){
			showConfirmDialog('Confirm Save Form', undefined, () => {
				closeConfirmDialog();
				save();
			})
		}
	}

	const isValid = () => {
		Object.values(errorList).forEach(value => {
			if(value){
				return false;
			}
		});
		return true;
	}

	const save = () => {
		// setLoading(true);
		let uploadData = {};
		uploadData.lead = id;
		uploadData.form_type = type;
		uploadData.rows = formData;

		showProgressDialog('Saving Form...');
		FormService.saveFormData(uploadData)
		.then(data => {
			if(data.success){
				closeProgressDialog();
				handleClose(false, true);
			} else {
				closeProgressDialog();
				showConfirmDialog1D('Something went wrong. Please try again.', undefined, () => {
					closeConfirmDialog1D();
				}, 'OK');
			}
		})
	}

  	return (
		<Dialog fullScreen disableBackdropClick disableEscapeKeyDown open={open} onClose={() => handleClose(true)} TransitionComponent={Transition}>
			<AppBar className={classes.appBar}>
				<Toolbar>
				<IconButton edge="start" color="inherit" onClick={() => handleClose(true)} aria-label="close">
					<CloseIcon />
				</IconButton>
				<Typography variant="h6" className={classes.title}>
					{`${id ? '' : 'New '}Form`}
				</Typography>
				<Button autoFocus color="inherit" onClick={exportFile} endIcon={<GetAppIcon/>}>
					Download
				</Button>
				</Toolbar>
			</AppBar>
			{/* <Backdrop className={classes.backdrop} open={loading} onClick={() => {}}>
				<div style={{padding: 50, backgroundColor: 'white', borderRadius: 8}}>
					<CircularProgress 
							size={40}
							thickness={4}/>
				</div>
			</Backdrop> */}
			<Grid container className={classes.page} justify="center">
				<Grid container item spacing={0} xs={12} lg={10} alignItems="flex-start" justify="center" direction="row">
					<Grid item xs={12} lg={12}>
						<Paper variant="outlined" className={classes.section} elevation={3} ref={componentRef}>
							{
								form && form.segments && form.segments.map((segment, index) => {
									return (
										<div key={index}>	
											<CardHeader
												className={classes.cardHeader}
												title={segment.description}
												titleTypographyProps={{variant: 'h6', style: {fontWeight: 600, color: 'white'}}}
											/>
											<Divider/>
											<div style={{minHeight: 100, marginRight: 16, marginLeft: 32, marginTop: 8, marginBottom: 16}}>
												<Grid container justify="center" spacing={2} alignItems="flex-start">
													{
														segment.rows.map((row, i) => {
															if(row.field_type === FORM_OPTIONS.static){
																return <StaticForm i={i + 1} row={row} lead={lead} key={i}/>;
															} else if(row.field_type === FORM_OPTIONS.text){
																return (
																	<TextForm i={i + 1} key={i} row={row} errorList={errorList} disabled={disabled} formData={formData} handleChange={handleChange}/>
																)
															} else if(row.field_type === FORM_OPTIONS.radio){
																return (
																	<RadioForm i={i + 1} key={i} row={row} errorList={errorList} disabled={disabled} formData={formData} handleChange={handleChange}/>
																)
															} else if(row.field_type === FORM_OPTIONS.group){
																return (
																	<GroupForm i={i + 1} key={i} row={row} formData={formData} disabled={disabled} errorList={errorList} handleChange={handleChange}/>
																)
															} else if(row.field_type === FORM_OPTIONS.check){
																return (
																	<CheckForm i={i + 1} key={i} row={row} formData={formData} disabled={disabled} errorList={errorList} handleChange={handleChange}/>
																)
															}
															return null;
														})
													}
												</Grid>
											</div>
										</div>
									)
								})
							}
						</Paper>
					</Grid>
					<Grid item xs={12} lg={12}>
						<Paper variant="outlined" style={{marginLeft: 16, marginRight: 16}} elevation={3}>
							<div style={{marginRight: 16, marginLeft: 16, marginTop: 16, marginBottom: 16}}>
								<Grid container justify="flex-start" spacing={2} alignItems="center">
									<Grid item xs={12} lg={12}>
										<div style={{float: 'right'}}>
											<Button variant="contained" disabled={disabled} color="primary" onClick={saveForm}>Save</Button>
										</div>
									</Grid>
								</Grid>
							</div>
						</Paper>
					</Grid>
				</Grid>
			</Grid>
		</Dialog>
	);
}

FillFormDialog.prototype = {
    open: PropTypes.bool.isRequired,
    id: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    onClose: PropTypes.func.isRequired
};

function StaticForm({row, lead, i}) {
	const classes = useStyles();
	let value = "-";
	if (typeof row.options === 'string') {
		value = lead[row.options];
	} else if (row.options.step) {
		let step = lead.steps && lead.steps.find(s => `${s.step_id}` === `${row.options.step}`);
		if (step) {
			value = step[row.options.field];
		}
	}
	return (
		<Grid item xs={12} lg={12} container justify="flex-start" spacing={1} alignItems="center">
			{
				i &&
				<Grid item xs={2} lg={1}>
					<Typography variant="subtitle1">{i}</Typography>
				</Grid>
			}
			<Grid item xs={4} lg={8}>
				<Typography variant="subtitle1" className={classes.subtitle}>{row.description}</Typography>
			</Grid>
			<Grid item xs={6} lg={3}>
				<Typography variant="subtitle1">{value}</Typography>
			</Grid>
		</Grid>
	);
}

function TextForm({i, row, formData, errorList, handleChange, hideText, disabled}) {
	const classes = useStyles();
	const r = formData.find(f => f.id === row.id);
	return (
		<Grid item xs={12} lg={12} container justify="flex-start" spacing={1} alignItems="center">
			{
				i &&
				<Grid item xs={2} lg={1}>
					<Typography variant="subtitle1">{i}</Typography>
				</Grid>
			}
			{
				!hideText && (
					<Grid item xs={4} lg={8}>
						<Typography variant="subtitle1" className={classes.subtitle}>
							{row.description}
							{row.required === 1 && <sup>*</sup>}
						</Typography>
					</Grid>
				)
			}
			<Grid item xs={6} lg={hideText ? 8: 3}>
				<FormControl variant="outlined" fullWidth>
					<TextField
						label={hideText ? row.description: ''}
						value={ r ? r.value : ''}
						required={row.required === 1}
						error={errorList[row.id]}
						margin={fieldMargin}
						multiline
						disabled={disabled}
						onChange={handleChange(row.id)}
						aria-describedby="outlined-weight-helper-text"
						variant="outlined"
						InputProps={{
							inputProps: {
								type: row.input || 'text'
							}
						}} />
				</FormControl>
			</Grid>
		</Grid>
	);
}

function GroupForm({i, row, errorList, handleChange, formData, disabled}) {
	const classes = useStyles();
	return (
		<Grid item xs={12} lg={12} container justify="flex-start" spacing={1} alignItems="flex-start">
			<Grid item xs={2} lg={1}>
				<Typography variant="subtitle1">{i}</Typography>
			</Grid>
			<Grid item xs={4} lg={8}>
				<Typography variant="subtitle1" className={classes.subtitle}>{row.description}</Typography>
			</Grid>
			<Grid container justify="flex-start" spacing={1} alignItems="center" item xs={6} lg={3}>
			{
				row.options.map((option, k) => {

					if(option.field_type === FORM_OPTIONS.check){
						return <CheckForm key={k} row={option} formData={formData} disabled={disabled} errorList={errorList} handleChange={handleChange} hideText={true}/>
					} else if(option.field_type === FORM_OPTIONS.text){
						return <TextForm key={k} row={option} formData={formData} disabled={disabled} errorList={errorList} handleChange={handleChange} hideText={true}/>
					}
					return null;
				})
			}
			</Grid>
			<Grid item xs={12} lg={12}>
				<Divider/>
			</Grid>
		</Grid>
	);
}

function RadioForm({i, row, handleChange, errorList, formData, disabled}) {
	const classes = useStyles();
	const r = formData.find(f => f.id === row.id);
	return (
		<Grid item xs={12} lg={12} container justify="flex-start" spacing={1} alignItems="center">
			{
				i &&
				<Grid item xs={2} lg={1}>
					<Typography variant="subtitle1">{i}</Typography>
				</Grid>
			}
			<Grid item xs={4} lg={8}>
				<Typography variant="subtitle1" className={classes.subtitle}>
					{row.description}
					{row.required === 1 && <sup>*</sup>}
				</Typography>
			</Grid>
			<Grid item xs={6} lg={3}>
				<FormControl disabled={disabled} required={row.required === 1} component="fieldset" error={errorList[row.id]}>
					<RadioGroup row aria-label="approved" name="approved" value={ r ? r.value : ''} onChange={handleChange(row.id)}>
						{
							row.options.map((option, k) => 
								<FormControlLabel key={k} value={option} control={<Radio />} label={option} />
							)
						}
					</RadioGroup>
					{errorList[row.id] && <FormHelperText>Please select an option</FormHelperText>}
				</FormControl>
			</Grid>
		</Grid>
	);
}

function CheckForm({i, row, errorList, handleChange, hideText, formData, disabled}) {
	const classes = useStyles();
	const r = formData.find(f => f.id === row.id);

	return (
		<Grid item xs={12} lg={12} container justify="flex-start" spacing={1} alignItems="center">
			{
				i &&
				<Grid item xs={2} lg={1}>
					<Typography variant="subtitle1">{i}</Typography>
				</Grid>
			}
			{
				!hideText && (
					<Grid item xs={4} lg={8}>
						<Typography variant="subtitle1" className={classes.subtitle}>
							{row.description}
							{row.required === 1 && <sup>*</sup>}
						</Typography>
					</Grid>
				)
			}
			<Grid item xs={6} lg={hideText ? 8: 3}>
				<FormControl required={row.required === 1} error={errorList[row.id]}>
					<FormControlLabel
						control={
							<Checkbox
								disabled={disabled}
								checked={r ? r.value === 1 : false}
								onChange={handleChange(row.id)}
								name="checkedB"
								color="primary"
							/>
						}
						label={row.options}
					/>
				</FormControl>
			</Grid>
		</Grid>
	);
}
