import Config from '../Config';
import {RestService} from './RestService';

export const FormService = {
    getFormTemplate: async function(type = null){
        return RestService.get(Config.form_template_url.replace('{type}', type), {})
        .then(response => response.json());
    },
    saveFormData: async function(body = null){
        return RestService.post(Config.save_form_data_url, {}, body)
        .then(response => response.json());
    },
    retrieveLeadFormData: async function(lead = null, type = null){
        return RestService.get(Config.retrieve_form_data_url.replace('{type}', type).replace('{lead}', lead), {})
        .then(response => response.json());
    },
    checkLeadFormStatus: async function(lead = null, type = null){
        return RestService.get(Config.check_form_status_url.replace('{type}', type).replace('{lead}', lead), {})
        .then(response => response.json());
    },
};