import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import MobileStepper from '@material-ui/core/MobileStepper';

const useStyles = makeStyles({
  root: {
    // maxWidth: 400,
    flexGrow: 1,
    backgroundColor: 'transparent'
  },
  rootLinear: {
    height: 10,
  }
});

export default function Stepper({current, total}) {
  const classes = useStyles();

  return (
    <MobileStepper
      variant="progress"
      steps={total + 1}
      position="static"
      activeStep={current}
      className={classes.root}
      LinearProgressProps={{color: 'secondary', classes: {root: classes.rootLinear}}}
    />
  );
}