import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import Avatar from '@material-ui/core/Avatar';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import { ListSubheader, ListItemSecondaryAction, Icon, Fade, CircularProgress, Typography } from '@material-ui/core';
import { BootstrapTooltip } from '../Utils';
import { AppContext } from '../AppContextProvider';
import { LeadService } from '../Services/LeadService';

export default function SearchDialog(props) {
    const {openLead, showSnackBar} = useContext(AppContext);
    const { onClose, text, open } = props;
    const [items, setItems] = useState([]);
    const [loading, setLoading] = useState(true);

    React.useEffect(() => {
      if(open && text.length  > 0){
        LeadService.searchLead(text, 0, 20)
        .then(data => {
          console.log(data);
          setItems(data.leads || []);
          setLoading(false);
        })
        .catch(error => {
          console.log(error);
          showSnackBar('Something weng wrong. Please try Again');
          setLoading(false);
          handleClose();
        })
      }
      return () => {
        setItems([]);
      }
    }, [open, text])

    const handleClose = () => {
      setItems([]);
      onClose();
    };

    const viewClick = (value) => {
        openLead(value.id);
        handleClose();
    };

    return (
      <Dialog fullWidth maxWidth="md" onClose={handleClose} aria-labelledby="simple-dialog-title" open={open}>
        <DialogTitle id="simple-dialog-title">{`Searching for ${text} `}</DialogTitle>
        <Fade
            in={loading}
            style={{
                transitionDelay: loading ? '10ms' : '0ms'
            }}
            unmountOnExit
            >
              <div style={{height: 200}}>
                <CircularProgress style={{position: 'relative', top: '30%', left: '45%'}}/>
              </div>
        </Fade>
        {
          !loading &&  (
              <List subheader={<ListSubheader></ListSubheader>}>
                {items.length > 0 ? 
                  items.map((item, index) => (
                    <ListItem key={index}>
                      <ListItemAvatar>
                          <Avatar>
                              {
                                  item.photo ? 
                                      <img height={50} width={50} src={item.photo.link} alt={item.full_name}/>
                                  :
                                    item.full_name.substring(0,1)
                              }
                          </Avatar>
                      </ListItemAvatar>
                      <ListItemText 
                        primary={`Name: ${item.full_name}, Branch: ${item.branch}`} 
                        secondary={`PAN: ${item.pan}, Email: ${item.email}, Mobile: ${item.mobile}`}
                        />
                      <ListItemSecondaryAction>
                        <BootstrapTooltip arrow title="View">
                            <Icon 
                                fontSize="small" 
                                style={{marginTop: 8, marginRight: 8}} 
                                onClick={(e) => {
                                    e.stopPropagation();
                                    viewClick(item)
                                }}>library_books</Icon>
                        </BootstrapTooltip>
                      </ListItemSecondaryAction>
                    </ListItem>
                    ))
                    : 
                    <Typography color="error" style={{marginLeft: 32}}>No matching results..</Typography>
                }
              </List>
          )
        }
        {
          items.length >= 20 &&
          <Typography variant="h5">Showing first 20 matching results...</Typography>
        }
      </Dialog>
    );
}

SearchDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  text: PropTypes.string.isRequired
};