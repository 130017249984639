import React, { useContext, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Button, ButtonBase, Checkbox, Divider, FormControlLabel, FormGroup, Grid, GridList, GridListTile, GridListTileBar, IconButton, ListItemText, Typography } from '@material-ui/core';
import { format_display_fancy_notime, getTimeInIST, openLink } from '../Utils';
import { USERTYPE_TO_TEXT, STEP_TYPE } from '../Constants';
import { DocumentService } from '../Services/DocumentService';
import ItemSelection from '../components/ItemSelection';
import { LeadService } from '../Services/LeadService';
import { AppContext } from '../AppContextProvider';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import GetAppIcon from '@material-ui/icons/GetApp';
import moment from 'moment';

const useStyles = makeStyles((theme) => ({
	right: {
        flex: 0.5, 
        textAlign: 'end'
    },
    time: {
        position: 'relative',
        top: '50%'
    },
    section: {
        flex: 0.5
    },
    sectionFull: {
        flex: 1,
        marginTop: 6,
        marginBottom: 6,
        marginLeft: 6
    },
    formControlAutoComplete: {
        zIndex: 3,
    },
    icon: {
        color: 'white'
    },  
}));

function ApprovalBlock({step}){
    const classes = useStyles();

    return (
        <div className={classes.sectionFull}>
            <Typography variant="body1">{`Comments: ${step.comments || '-'}`}</Typography>
            {
                step.skippable === 1 && (<Typography variant="body1">{`Skipped: ${step.skipped === 1 ? 'Yes' : 'No'}`}</Typography>)
            }
        </div>
    );
}

function Feedback({step}){
    const classes = useStyles();
    const {showSnackBar, showConfirmDialog, closeConfirmDialog, showProgressDialog, closeProgressDialog} = useContext(AppContext);

    const resend = () => {
        showConfirmDialog('Confirm Resend?', undefined, () => {
            closeConfirmDialog();
            showProgressDialog('Re-Sending...');
            LeadService.resendSMS(step.lead_id, step.step_id)
            .then((data) => {
                closeProgressDialog();
                if(data.sent){
                    showSnackBar('Resent Feedback Link', 'info');
                }
            })
            .catch(error => {
                console.log(error);
                closeProgressDialog();
            }) 
        })
    }

    return (
        <div className={classes.sectionFull}>
            <Typography variant="body1">{`Comments: ${step.comments || '-'}`}</Typography>
            {
                step.skippable === 1 && (<Typography variant="body1">{`Skipped: ${step.skipped === 1 ? 'Yes' : 'No'}`}</Typography>)
            }
            {
                step.skipped === 0 && (
                    <Grid item xs={12} lg={4}>
                        <Button color="primary" onClick={resend}>RESEND</Button>
                    </Grid>
                )
            }
        </div>
    );
}

function DummyBlock({step}){
    const classes = useStyles();

    return (
        <div className={classes.sectionFull}>
            <Typography variant="body1">{`Comments: ${step.comments || '-'}`}</Typography>
            {
                step.skippable === 1 && (<Typography variant="body1">{`Skipped: ${step.skipped === 1 ? 'Yes' : 'No'}`}</Typography>)
            }
        </div>
    );
}

function InspectionBlock({step}){
    const classes = useStyles();

    const {openNewForm} = useContext(AppContext);

    const view = () => {
        openNewForm(step.lead_id, step.step_ui_type.toUpperCase());
    }

    return (
        <div className={classes.sectionFull}>
            <Typography variant="body1">{`Comments: ${step.comments || '-'}`}</Typography>
            {
                step.skippable === 1 && (<Typography variant="body1">{`Skipped: ${step.skipped === 1 ? 'Yes' : 'No'}`}</Typography>)
            }
            <Button color="primary" onClick={view}>View Inspection</Button>
        </div>
    );
}

function ReceiptBlock({step}){
    const classes = useStyles();

    return (
        <div className={classes.sectionFull}>
            <Typography variant="body1">{`Comments: ${step.comments || '-'}`}</Typography>
            {
                step.skippable === 1 && (<Typography variant="body1">{`Skipped: ${step.skipped === 1 ? 'Yes' : 'No'}`}</Typography>)
            }
            <Typography variant="body2">{`Received On: ${step.modified_date ? getTimeInIST(step.modified_date).format(format_display_fancy_notime) : '-'}`}</Typography>
        </div>
    );
}

function CodeBlock({step}){
    const classes = useStyles();

    return (
        <div className={classes.sectionFull}>
            <Typography variant="body1">{`Comments: ${step.comments || '-'}`}</Typography>
            {
                step.skippable === 1 && (<Typography variant="body1">{`Skipped: ${step.skipped === 1 ? 'Yes' : 'No'}`}</Typography>)
            }
            <Typography variant="body2">{`Code: ${step.field1 || '-'}`}</Typography>
            <Typography variant="body2">{`Any client complaint observed online for PAN or the name?: ${step.field2 === 'y'  ? 'Yes' : 'No'}`}</Typography>
        </div>
    );
}

function CodeBlock2({step}){
    const classes = useStyles();

    return (
        <div className={classes.sectionFull}>
            <Typography variant="body1">{`Comments: ${step.comments || '-'}`}</Typography>
            {
                step.skippable === 1 && (<Typography variant="body1">{`Skipped: ${step.skipped === 1 ? 'Yes' : 'No'}`}</Typography>)
            }
            <Typography variant="body2">{`Code: ${step.field1 || '-'}`}</Typography>
        </div>
    );
}

function CheckBlock({step}){
    const classes = useStyles();

    return (
        <div className={classes.sectionFull}>
            <Typography variant="body1">{`Comments: ${step.comments || '-'}`}</Typography>
            {
                step.skippable === 1 && (<Typography variant="body1">{`Skipped: ${step.skipped === 1 ? 'Yes' : 'No'}`}</Typography>)
            }
        </div>
    );
}

function CourierBlock({step}){
    const classes = useStyles();

    return (
        <div className={classes.sectionFull}>
            <Typography variant="body1">{`Channel: ${step.field1 || '-'}`}</Typography>
            <Typography variant="body1">{`Date of Dispatch: ${step.field2 ? moment(step.field2).format(format_display_fancy_notime) : '-'}`}</Typography>
            <Typography variant="body1">{`POD No: ${step.field3 || '-'}`}</Typography>
            {
                step.field4 && (
                    <Typography variant="body1">Tracking Link: <a rel="noopener noreferrer" href={step.field4} target="_blank">Click here to track</a></Typography>
                )
            }
        </div>
    );
}

function SubListBlock({step}){
    const classes = useStyles();
    const [substeps, setSteps] = useState([]);

    React.useEffect(() => {
        // showProgressDialog('Loading...');
        LeadService.getSubSteps(step.step_id)
        .then(data => {
            let result;
            let steps = [];
            if(step.field1){
                result = JSON.parse(step.field1);
            }
            data.forEach(ss => {
                let value = 0;
                if(result && result[ss.id] === 1){
                    value = 1;
                }
                steps.push({...ss, value: value})
            });
            setSteps(steps);
            // closeProgressDialog();
        })
        .catch(error => {
            console.log(error);
            // closeProgressDialog();
        })
        return () => {
            setSteps([]);
        }
    }, [true])

    return (
        <div className={classes.sectionFull}>
            <Typography variant="body1">{`Comments: ${step.comments || '-'}`}</Typography>
            {
                step.skippable === 1 && (<Typography variant="body1">{`Skipped: ${step.skipped === 1 ? 'Yes' : 'No'}`}</Typography>)
            }
            <FormGroup>
                {
                    substeps.map((s,i) =>{
                        if(s.type === 'check'){
                            return <FormControlLabel
                                        key={i}
                                        disabled
                                        control={
                                        <Checkbox
                                            icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                                            checkedIcon={<CheckBoxIcon fontSize="small" />}
                                            name={s.name}
                                        />
                                        }
                                        label={s.name}
                                        checked={s.value === 1}
                                    />
                        }
                        return null;
                    })
                }
            </FormGroup>
        </div>
    );
}

function PaymentBlock({step}){
    const classes = useStyles();

    return (
        <div className={classes.sectionFull}>
            <Typography variant="body1">{`Comments: ${step.comments || '-'}`}</Typography>
            {
                step.skippable === 1 && (<Typography variant="body1">{`Skipped: ${step.skipped === 1 ? 'Yes' : 'No'}`}</Typography>)
            }
            <Typography variant="body2">{step.skipped === 0 && `Payment Link Sent On: ${step.modified_date ? getTimeInIST(step.modified_date).format(format_display_fancy_notime) : '-'}`}</Typography>
        </div>
    );
}

function EmailBlock({step}){
    const classes = useStyles();
    const {showSnackBar, showConfirmDialog, closeConfirmDialog, showProgressDialog, closeProgressDialog,} = useContext(AppContext);
    const [files, setFiles] = useState([]);

    React.useEffect(() => {
        DocumentService.getAll()
        .then(data => {
            let files = [];
            step.field1 && step.field1.split().forEach(file => {
                let f = data.find(d => d.id === file);
                if(f){
                    files.push({label: `${f.document_type} - ${f.original_file_name}`, value: f.id});
                }
            })
            setFiles(files);
        })
        .catch(error => {
            console.log(error);
        });
        return () => {
            setFiles([]);
        }
    }, [true]);

    const resend = () => {
        showConfirmDialog('Confirm Resend?', undefined, () => {
            closeConfirmDialog();
            showProgressDialog('Re-Sending...');
            LeadService.resendEmail(step.lead_id, step.step_id)
            .then((data) => {
                closeProgressDialog();
                if(data.sent){
                    showSnackBar('Resent Email', 'info');
                }
            })
            .catch(error => {
                console.log(error);
                closeProgressDialog();
            }) 
        })
    }

    return (
        <div className={classes.sectionFull}>
            <Grid container justify="flex-start" alignItems="center">
                <Grid item xs={12} lg={12}>
                    <ItemSelection
                        disabled
                        multiple 
                        label='Select Files' 
                        value={files} 
                        formClass={classes.formControlAutoComplete}
                        optionLabel='label' 
                        options={files} 
                        style={{marginBottom: 16, marginRight: 16 }}
                        selected={(selected) => {}}/>
                </Grid>
                <Grid item xs={12} lg={4}>
                    <Typography variant="body1">{`Comments: ${step.comments || '-'}`}</Typography>
                    {
                        step.skippable === 1 && (<Typography variant="body1">{`Skipped: ${step.skipped === 1 ? 'Yes' : 'No'}`}</Typography>)
                    }
                </Grid>
                
                {
                    step.skipped === 0 && (
                        <Grid item xs={12} lg={4}>
                            <Button color="primary" onClick={resend}>RESEND EMAIL</Button>
                        </Grid>
                    )
                }
            </Grid>
        </div>
    );
}

function EmailBlock2({step}){
    const classes = useStyles();
    const {showSnackBar, showConfirmDialog, closeConfirmDialog, showProgressDialog, closeProgressDialog,} = useContext(AppContext);
    const [files, setFiles] = useState([]);

    React.useEffect(() => {
        Promise.all([DocumentService.getAll(), LeadService.getLeadStepDocuments(step.lead_id, step.step_id)])
        .then(datas => {
            let files = [];
            step.field1 && step.field1.split(',').forEach(file => {
                let parts = file.split('|');
                if(parts.length === 2){
                    let f = null;
                    if(parts[1] === 'doc'){
                        if(datas[0]){
                            f = datas[0].find(d => d.id === parts[0]);
                        }
                    }else if(parts[1] === 'lsd'){
                        if(datas[1]){
                            f = datas[1].find(d => d.id === parts[0]);
                        }
                    }
                    if(f){
                        files.push({label: `${f.document_type} - ${f.original_file_name}`, value: f.id});
                    }
                }
            })
            setFiles(files);
        })
        .catch(error => {
            console.log(error);
        });
        return () => {
            setFiles([]);
        }
    }, [true]);

    const resend = () => {
        showConfirmDialog('Confirm Resend?', undefined, () => {
            closeConfirmDialog();
            showProgressDialog('Re-Sending...');
            LeadService.resendEmail(step.lead_id, step.step_id)
            .then((data) => {
                closeProgressDialog();
                if(data.sent){
                    showSnackBar('Resent Email', 'info');
                }
            })
            .catch(error => {
                console.log(error);
                closeProgressDialog();
            }) 
        })
    }

    return (
        <div className={classes.sectionFull}>
            <Grid container justify="flex-start" alignItems="center">
                <Grid item xs={12} lg={12}>
                    <ItemSelection
                        disabled
                        multiple 
                        label='Select Files' 
                        formClass={classes.formControlAutoComplete}
                        value={files} 
                        optionLabel='label' 
                        options={files} 
                        getOptionSelected={(option, value) => value.value === option.value}
                        style={{marginBottom: 16, marginRight: 16 }}
                        selected={(selected) => {}}/>
                </Grid>
                <Grid item xs={12} lg={4}>
                    <Typography variant="body1">{`Comments: ${step.comments || '-'}`}</Typography>
                    {
                        step.skippable === 1 && (<Typography variant="body1">{`Skipped: ${step.skipped === 1 ? 'Yes' : 'No'}`}</Typography>)
                    }
                </Grid>
                {
                    step.skipped === 0 && (
                        <Grid item xs={12} lg={4}>
                            <Button color="primary" onClick={resend}>RESEND EMAIL</Button>
                        </Grid>
                    )
                }
            </Grid>
        </div>
    );
}

function UploadBlock({step}){
    const classes = useStyles();
    const [files, setFiles] = useState([]);
    const {mobileView} = useContext(AppContext);
    React.useEffect(() => {
        fetchData();
        return () => {
            setFiles([]);
        }
    }, [true]);

    const fetchData = () => {
        LeadService.getLeadStepDocuments(step.lead_id, step.step_id)
        .then(data => {
            if(data){
                setFiles(data);
            }
        })
        .catch(error => {
            console.log(error);
        });
    }

    return (
        <div className={classes.sectionFull}>
            <Grid container spacing={2} justify="flex-start" alignItems="center">
                <Grid item xs={12} lg={4}>
                    <Typography variant="body1">{`Comments: ${step.comments || '-'}`}</Typography>
                    {
                        step.skippable === 1 && (<Typography variant="body1">{`Skipped: ${step.skipped === 1 ? 'Yes' : 'No'}`}</Typography>)
                    }
                </Grid>
                <Grid item xs={8} lg={12}>
                    <GridList cellHeight={230} className={classes.gridList} cols={mobileView ? 1: 3}>
                        {
                            files.map((data, i) => {
                                return (
                                    <GridListTile key={i}>
                                        <AddedImageHolder
                                            link={data.link || null} 
                                            remove={() => {
                                                
                                            }}
                                        />
                                        <GridListTileBar
                                            classes={{root: classes.titleBar, subtitle: classes.subtitle}}
                                            //title={data.original_file_name}
                                            actionIcon={
                                                <>
                                                    <IconButton aria-label={`download`} className={classes.icon} size="small" onClick={() => openLink(data.link)}>
                                                        <GetAppIcon />
                                                    </IconButton>
                                                </>
                                            }
                                        />
                                    </GridListTile>
                                )
                            })
                        }
                    </GridList>
                </Grid>
            </Grid>
        </div>
    );
}

function AddedImageHolder(props){
	const [link, setLink] = React.useState(null);

	React.useEffect(() => {
		setLink(props.link);
	}, [props.link])

	let classes = useStyles();

	// const remove = () => {
	// 	props.remove();
	// }

	return (
		<ButtonBase
			focusRipple
			className={classes.image}>
			<img 
				src={link} 
				height="200" width="200" 
				alt=""/>
		</ButtonBase>
	)
}

const CustomStepLabel = (props) => {
    const {step} = props;
    const classes = useStyles();

    const cDate = step.modified_date ? getTimeInIST(step.modified_date).fromNow() : '-';

    const getCenterBlock = () => {
        switch(step.step_ui_type){
            case STEP_TYPE.approval:
                return <ApprovalBlock step={step}/>;
            case STEP_TYPE.email:
                return <EmailBlock step={step}/>;
            case STEP_TYPE.email_custom_file:
                case STEP_TYPE.welcome:
                return <EmailBlock2 step={step}/>;
            case STEP_TYPE.receipt:
                return <ReceiptBlock step={step}/>;
            case STEP_TYPE.payment_link:
                return <PaymentBlock step={step}/>;
            case STEP_TYPE.code:
                return <CodeBlock step={step}/>;
            case STEP_TYPE.code2:
                return <CodeBlock2 step={step}/>;
            case STEP_TYPE.check:
                return <CheckBlock step={step}/>;
            case STEP_TYPE.sublist:
                return <SubListBlock step={step}/>;
            case STEP_TYPE.inspection:
                return <InspectionBlock step={step}/>;
            case STEP_TYPE.upload_img:
                return <UploadBlock step={step}/>;
            case STEP_TYPE.courier:
                return <CourierBlock step={step}/>;
            case STEP_TYPE.feedback:
                return <Feedback step={step}/>;
            default:
                return <DummyBlock step={step}/>;
        }
    }
    
    return (
        <div>
            <div style={{display: 'flex', width: '100%'}}>
                <div className={classes.section}>
                    <ListItemText
                        primary={step.name}
                        secondary={`Performed By: ${step.ownership ? USERTYPE_TO_TEXT[step.ownership] : '-'} ${step.performed_by || ''}`}
                        />
                </div>
                {getCenterBlock()}
                <div className={classes.right}>
                    <Typography variant="caption" className={classes.time}>{`Completed ${cDate}`}</Typography>
                </div>
            </div>
            <Divider />
        </div>
    )
};

export default CustomStepLabel;