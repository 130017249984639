import React from "react";
import { Route, Switch } from "react-router-dom";
import Home from "./HomePage/home";
import Login from "./LoginPage/Login";
import NotFound from "./NotFound/NotFound";
import ViewLeads from "./LeadPage/ViewLeads";
import ViewLead from "./LeadPage/ViewLead";
import DocumentPage from "./DocumentPage/DocumentPage";
import FillForm from "./Forms/FillForm";
import ManageNotifications from "./Tools/ManageNotifications";

export default () =>
  <Switch>
    <Route path={`/login`} exact component={Login} />
    <Route path={`/`} exact component={Home} />
    <Route path={`/manage/documents`} exact component={DocumentPage} />
    <Route path={`/lead/:id`} exact component={ViewLead} />
    <Route path={`/leads`} exact component={ViewLeads} />
    <Route path={`/form/:type/:id`} exact component={FillForm} />
    <Route path={`/manage/notifications`} exact component={ManageNotifications} />
    
    {/* Not found */}
    <Route component={NotFound} />
  </Switch>;