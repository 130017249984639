import Config from '../Config';
import {RestService} from './RestService';

export const MasterService = {
    getPincodeList: async function(filter){
        return RestService.get(`${Config.pincode_url}?filter=${filter}`, {})
        .then(response => response.json());
    },
    getAceUsers: async function(type = null){
        return RestService.get(`${Config.ace_users_url}?type=${type}`, {})
        .then(response => response.json());
    },
    getConfig: async function(){
        return RestService.get(Config.config_url, {})
        .then(response => response.json());
    },
    getStepConfig: async function(){
        return RestService.get(Config.step_config_url, {})
        .then(response => response.json());
    },
    updatePreference: async function(body, header = {}){
        return RestService.post(Config.config_url, header, body)
        .then(response => response.json());
    },
    updateStepPreference: async function(body, header = {}){
        return RestService.post(Config.step_config_url, header, body)
        .then(response => response.json());
    },
    getMasterCharges: async function(){
        return RestService.get(`${Config.charges_url}`, {})
        .then(response => response.json());
    },
};