import React from "react";
import PropTypes from 'prop-types';
import { makeStyles } from "@material-ui/core/styles";
import { Grid} from "@material-ui/core";
import { MuiPickersUtilsProvider, KeyboardDatePicker } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import { format_display } from "../Utils";
// import { format_display_fancy_notime } from "../Utils";

const useStyles = makeStyles(theme => ({
    formControl: {
        margin: theme.spacing(1),
        marginTop: theme.spacing(1),
        // maxWidth: 120,
        marginLeft: 0,
        marginRight: 0,
        [theme.breakpoints.up('md')]: {
            // marginLeft: theme.spacing(1),
        },
    },
}));

export default function SingleDate(props){
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);
    const [date, setDate] = React.useState(null);

    React.useEffect(() => {
        if(props.date) setDate(props.date);
    }, [props]);

    return (
        <Grid container justify="flex-start">
            <Grid item xs={12} lg={12}>
                <MuiPickersUtilsProvider utils={MomentUtils}>
                    <KeyboardDatePicker
                        fullWidth
                        open={open}
                        disabled={props.disabled || false}
                        onOpen={() => setOpen(true)}
                        onClose={() => setOpen(false)}
                        variant="inline"
                        inputVariant="outlined"
                        margin={props.margin || "normal"}
                        label={props.label || 'Date'}
                        value={date}
                        maxDate={props.maxDate || undefined}
                        minDate={props.minDate || undefined}
                        onChange={(date) => {
                            setDate(date);
                            props.setDate(date);
                            setOpen(false);
                        }}
                        placeholder="20/01/2020"
                        format={format_display}
                        error={props.error || false}
                        className={classes.formControl}
                        onKeyUp={props.onKeyUp || undefined}
                        required={props.required || false}
                    />
                </MuiPickersUtilsProvider>
            </Grid>
            {/* {
                props.maxDate &&
                <Grid item xs={12} lg={12}>
                    <FormHelperText style={{fontSize: 10, textAlign: 'right'}}>Data available till {moment(props.maxDate).format(format_display_fancy_notime)}</FormHelperText>
                </Grid>
            } */}
        </Grid>
    );
};

SingleDate.propTypes = {
    date: PropTypes.object,
    setDate: PropTypes.func.isRequired,
    minDate: PropTypes.object,
    maxDate: PropTypes.object,
    label: PropTypes.string,
    disabled: PropTypes.bool,
    error: PropTypes.bool,
    required: PropTypes.bool,
};
  