import React, { useContext, useEffect } from 'react'
import { AppContext } from '../AppContextProvider'

function FillForm({match}) {

    const {openNewForm, closeNewForm} = useContext(AppContext);
    const id = match.params.id || null;
    const type = match.params.type || null;

    useEffect(() => {
        if(id && type){
            openNewForm(id, type);
        }
        return () => {
            closeNewForm();
        }
    }, [id, type])

    return (
        <div>
            
        </div>
    )
}

export default FillForm
