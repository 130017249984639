import React, { useContext, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Button, ButtonBase, Checkbox, Divider, FormControl, FormControlLabel, FormGroup, Grid, GridList, GridListTile, ListItemText, Radio, RadioGroup, TextField, Typography } from '@material-ui/core';
import { STEP_TYPE, USERTYPE_TO_TEXT } from '../Constants';
import { DocumentService } from '../Services/DocumentService';
import ItemSelection from '../components/ItemSelection';
import { AppContext } from '../AppContextProvider';
import { LeadService } from '../Services/LeadService';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import DeleteIcon from '@material-ui/icons/Delete';
import SingleDate from '../components/SingleDate';
import { format_without_time } from '../Utils';
import moment from 'moment';

const useStyles = makeStyles((theme) => ({
    root: {
        marginLeft: theme.spacing(2)
    },
    right: {
        flex: 0.5,
        textAlign: 'end'
    },
    time: {
        position: 'relative',
        top: '50%'
    },
    section: {
        flex: 0.5
    },
    sectionFull: {
        flex: 1,
        marginTop: 6,
        marginBottom: 6
    },
    formControlAutoComplete: {
        zIndex: 3,
    },
    gridList: {
        margin: 16,
        width: '100%'
    },
    image: {
        margin: 12,
        position: 'relative',
        height: 200,
        width: '90% !important',
        [theme.breakpoints.down('xs')]: {
            width: '100% !important', // Overrides inline-style
            height: 100,
        },
        '&:hover,  &Mui-focusVisible': {
            zIndex: 1,
            '& $imageBackdrop': {
                opacity: 0.7,
            },
            '& $imageMarked': {
                opacity: 0,
            },
            '& $imageTitle': {
                border: '4px solid currentColor',
            },
        },
    },
    imageBackdrop: {
        borderRadius: 8,
        position: 'absolute',
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        backgroundColor: theme.palette.common.black,
        opacity: 0.3,
        transition: theme.transitions.create('opacity'),
    },
    imageTitle: {
        position: 'relative',
        padding: `${theme.spacing(0)}px ${theme.spacing(4)}px ${theme.spacing(0)}px`,
    },
    imageMarked: {
        height: 3,
        width: 18,
        backgroundColor: theme.palette.common.white,
        position: 'absolute',
        bottom: -2,
        left: 'calc(50% - 9px)',
        transition: theme.transitions.create('opacity'),
    },
    imageButton: {
        position: 'absolute',
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: theme.palette.common.white,
    },
    imageButtonBottom: {
        position: 'absolute',
        left: 0,
        right: 0,
        // top: 0,
        // bottom: 0,
        bottom: 10,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: theme.palette.common.white,
    },
}));

function ApprovalBlock({ step, handleChange, disabled }) {
    const classes = useStyles();

    const change = (event) => {
        // console.log(event.target.value);
        handleChange(event.target.name, event.target.value);
    }

    return (
        <div className={classes.sectionFull}>
            <Grid container justify="flex-start" alignItems="center">
                <Grid item xs={12} lg={12}>
                    <FormControl disabled={disabled} required component="fieldset">
                        <RadioGroup row aria-label="approved" name="approved" value={step.approved} onChange={change}>
                            <FormControlLabel value={'1'} control={<Radio />} label="Approved" />
                            <FormControlLabel value={'-1'} control={<Radio />} label="Rejected" />
                        </RadioGroup>
                    </FormControl>
                </Grid>
                <Grid item xs={12} lg={4}>
                    <FormControl variant="outlined" fullWidth>
                        <TextField
                            label={step.textfield_title || 'Comments'}
                            value={step.comments || ''}
                            margin="dense"
                            name="comments"
                            disabled={disabled}
                            onChange={change}
                            aria-describedby="outlined-weight-helper-text"
                            variant="outlined"
                            InputProps={{
                                inputProps: {
                                    maxLength: 100
                                },
                            }}
                        />
                    </FormControl>
                </Grid>
            </Grid>
        </div>
    );
}

function ReceiptBlock({ step, handleChange, disabled }) {
    const classes = useStyles();

    const change = (event) => {
        // console.log(event.target.value);
        handleChange(event.target.name, event.target.value);
    }

    return (
        <div className={classes.sectionFull}>
            <Grid container justify="flex-start" alignItems="center">
                <Grid item xs={12} lg={12}>
                    <FormControl disabled={disabled} required component="fieldset">
                        <RadioGroup row aria-label="approved" name="approved" value={step.approved} onChange={change}>
                            <FormControlLabel value={'1'} control={<Radio />} label="Received" />
                        </RadioGroup>
                    </FormControl>
                </Grid>
                <Grid item xs={12} lg={4}>
                    <FormControl variant="outlined" fullWidth>
                        <TextField
                            label={step.textfield_title || 'Comments'}
                            value={step.comments || ''}
                            margin="dense"
                            name="comments"
                            onChange={change}
                            aria-describedby="outlined-weight-helper-text"
                            variant="outlined"
                            disabled={disabled}
                            InputProps={{
                                inputProps: {
                                    maxLength: 100
                                },
                            }}
                        />
                    </FormControl>
                </Grid>
            </Grid>
        </div>
    );
}

function CheckBlock({ step, handleChange, disabled }) {
    const classes = useStyles();

    const change = (event) => {
        // console.log(event.target.value);
        handleChange(event.target.name, event.target.value);
    }

    return (
        <div className={classes.sectionFull}>
            <Grid container spacing={2} justify="flex-start" alignItems="center">
                <Grid item xs={12} lg={4}>
                    <FormControl variant="outlined" fullWidth>
                        <TextField
                            label={step.textfield_title || 'Comments'}
                            value={step.comments || ''}
                            margin="dense"
                            disabled={disabled}
                            name="comments"
                            onChange={change}
                            aria-describedby="outlined-weight-helper-text"
                            variant="outlined"
                            InputProps={{
                                inputProps: {
                                    maxLength: 100
                                },
                            }}
                        />
                    </FormControl>
                </Grid>
            </Grid>
        </div>
    );
}

function CodeBlock({ step, handleChange, disabled }) {
    const classes = useStyles();

    const change = (event) => {
        // console.log(event.target.value);
        handleChange(event.target.name, event.target.value);
    }

    return (
        <div className={classes.sectionFull}>
            <Grid container spacing={2} justify="flex-start" alignItems="center">
                <Grid item xs={12} lg={12}>
                    <Typography variant='subtitle1'>Any client complaint observed online for PAN or the name?</Typography>
                </Grid>
                <Grid item xs={12} lg={12}>
                    <FormControl component="fieldset">
                        <RadioGroup row aria-label="complaint" name="field2" value={step.field2 || 'n'} onChange={change}>
                            <FormControlLabel value="y" control={<Radio />} label="Yes" />
                            <FormControlLabel value="n" control={<Radio />} label="No" />
                        </RadioGroup>
                    </FormControl>
                </Grid>
                <Grid item xs={12} lg={12}>
                    <Divider variant='fullWidth' />
                </Grid>
                <Grid item xs={12} lg={2}>
                    <FormControl variant="outlined" fullWidth>
                        <TextField
                            label="Code"
                            value={step.field1 || ''}
                            margin="dense"
                            name="field1"
                            onChange={change}
                            disabled={disabled}
                            aria-describedby="outlined-weight-helper-text"
                            variant="outlined"
                            InputProps={{
                                inputProps: {
                                    maxLength: 100
                                },
                            }}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={12} lg={4}>
                    <FormControl variant="outlined" fullWidth>
                        <TextField
                            label={step.textfield_title || 'Comments'}
                            disabled={disabled}
                            value={step.comments || ''}
                            margin="dense"
                            name="comments"
                            onChange={change}
                            aria-describedby="outlined-weight-helper-text"
                            variant="outlined"
                            InputProps={{
                                inputProps: {
                                    maxLength: 100
                                },
                            }}
                        />
                    </FormControl>
                </Grid>
            </Grid>
        </div>
    );
}

function CodeBlock2({ step, handleChange, disabled }) {
    const classes = useStyles();

    const change = (event) => {
        // console.log(event.target.value);
        handleChange(event.target.name, event.target.value);
    }

    return (
        <div className={classes.sectionFull}>
            <Grid container spacing={2} justify="flex-start" alignItems="center">
                <Grid item xs={12} lg={2}>
                    <FormControl variant="outlined" fullWidth>
                        <TextField
                            label="Code"
                            value={step.field1 || ''}
                            margin="dense"
                            name="field1"
                            onChange={change}
                            disabled={disabled}
                            aria-describedby="outlined-weight-helper-text"
                            variant="outlined"
                            InputProps={{
                                inputProps: {
                                    maxLength: 100
                                },
                            }}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={12} lg={2}>
                    <FormControl variant="outlined" fullWidth>
                        <TextField
                            label="Password"
                            defaultValue={step.field2 || step.master_field1 || ''}
                            margin="dense"
                            name="field2"
                            onChange={change}
                            disabled={disabled}
                            aria-describedby="outlined-weight-helper-text"
                            variant="outlined"
                            InputProps={{
                                inputProps: {
                                    maxLength: 100
                                },
                            }}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={12} lg={4}>
                    <FormControl variant="outlined" fullWidth>
                        <TextField
                            label={step.textfield_title || 'Comments'}
                            disabled={disabled}
                            value={step.comments || ''}
                            margin="dense"
                            name="comments"
                            onChange={change}
                            aria-describedby="outlined-weight-helper-text"
                            variant="outlined"
                            InputProps={{
                                inputProps: {
                                    maxLength: 100
                                },
                            }}
                        />
                    </FormControl>
                </Grid>
            </Grid>
        </div>
    );
}

function EmailBlock({ step, handleChange, disabled }) {
    const classes = useStyles();
    const [files, setFiles] = useState([]);
    const [selectedFiles, setSelectedFiles] = useState([]);

    React.useEffect(() => {
        DocumentService.getAll()
            .then(data => {
                let files = [];
                data.forEach((f) => {
                    files.push({ label: `${f.document_type} - ${f.original_file_name}`, value: f.id });
                });
                setFiles(files);
            })
            .catch(error => {
                console.log(error);
            });
        return () => {
            setFiles([]);
        }
    }, [true]);

    const change = (event) => {
        // console.log(event.target.value);
        handleChange(event.target.name, event.target.value);
    }

    const changeSelected = (files) => {
        let ids = files.map(f => f.value).join();
        // console.log(files, ids);
        handleChange('field1', ids);
    }

    return (
        <div className={classes.sectionFull}>
            <Grid container spacing={2} justify="flex-start" alignItems="center">
                <Grid item xs={12} lg={6}>
                    <ItemSelection
                        clearable={true}
                        multiple
                        label='Select Files'
                        value={selectedFiles}
                        disabled={disabled}
                        optionLabel='label'
                        formClass={classes.formControlAutoComplete}
                        options={files}
                        selected={(files) => {
                            setSelectedFiles(files);
                            changeSelected(files);
                        }} />
                </Grid>
                <Grid item xs={12} lg={4}>
                    <FormControl variant="outlined" fullWidth>
                        <TextField
                            label={step.textfield_title || 'Comments'}
                            value={step.comments || ''}
                            margin="dense"
                            name="comments"
                            disabled={disabled}
                            onChange={change}
                            aria-describedby="outlined-weight-helper-text"
                            variant="outlined"
                            InputProps={{
                                inputProps: {
                                    maxLength: 100
                                },
                            }}
                        />
                    </FormControl>
                </Grid>
            </Grid>
        </div>
    );
}

function EmailBlock2({ step, handleChange, disabled }) {
    const classes = useStyles();
    const [files, setFiles] = useState([]);
    const [selectedFiles, setSelectedFiles] = useState([]);
    const hiddenFileInput = React.useRef(null);
    const { showProgressDialog, closeProgressDialog, showSnackBar } = useContext(AppContext);

    React.useEffect(() => {
        fetchData();
        return () => {
            setFiles([]);
        }
    }, [true]);

    const fetchData = (fileId = null) => {
        Promise.all([DocumentService.getAll(), LeadService.getLeadStepDocuments(step.lead_id, step.step_id)])
            .then(datas => {
                let ofiles = [];
                let selectedFile = null;
                if (datas[0]) {
                    datas[0].forEach((f) => {
                        ofiles.push({ label: `${f.document_type} - ${f.original_file_name}`, value: f.id, type: 'doc' });
                    });
                }
                if (datas[1]) {
                    datas[1].forEach((f) => {
                        ofiles.push({ label: `${f.original_file_name}`, value: f.id, type: 'lsd' });
                    });
                }
                setFiles(ofiles);
                if (fileId) {
                    selectedFile = ofiles.find(f => f.value === fileId);
                }
                if (selectedFile) {
                    let newFiles = [...selectedFiles, selectedFile];
                    setSelectedFiles(newFiles);
                    changeSelected(newFiles);
                }

            })
            .catch(error => {
                console.log(error);
            });
    }

    const change = (event) => {
        // console.log(event.target.value);
        handleChange(event.target.name, event.target.value);
    }

    const changeSelected = (files) => {
        let ids = files.map(f => f.value + "|" + f.type).join();
        // console.log(files, ids);
        handleChange('field1', ids);
    }

    const handleClick = () => {
        hiddenFileInput.current.click();
    }

    const upload = (event) => {
        const fileUploaded = event.target.files[0];
        const formData = new FormData();
        formData.append('file', fileUploaded || null);
        showProgressDialog('Uploading...');
        // setLoading(true);
        LeadService.addLeadStepDocument(step.lead_id, step.step_id, formData)
            .then(data => {
                if (data.success) {
                    fetchData(data.id);
                } else {
                    showSnackBar('Something went wrong. Please try uploading again.', 'error');
                }
                closeProgressDialog();
            })
            .catch(error => {
                console.log(error);
                showSnackBar('Something went wrong. Please try again.', 'error', 1000);
            })
    }

    return (
        <div className={classes.sectionFull}>
            <Grid container spacing={2} justify="flex-start" alignItems="center">
                <Grid item xs={8} lg={8}>
                    <ItemSelection
                        clearable={true}
                        multiple
                        label='Select Files'
                        value={selectedFiles}
                        disabled={disabled}
                        optionLabel='label'
                        options={files}
                        formClass={classes.formControlAutoComplete}
                        getOptionSelected={(option, value) => value.value === option.value}
                        selected={(files) => {
                            setSelectedFiles(files);
                            changeSelected(files);
                        }} />
                </Grid>
                <Grid item xs={4} lg={2}>
                    <Button fullWidth color="primary" disabled={disabled} variant="contained" onClick={handleClick} startIcon={<CloudUploadIcon />} style={{ marginTop: 8 }}>Upload</Button>
                    <input
                        accept="image/*,application/pdf, application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint, text/plain"
                        type="file"
                        onChange={upload}
                        onClick={(event) => event.target.value = null} ref={hiddenFileInput}
                        style={{ display: 'none' }} />
                </Grid>
                <Grid item xs={12} lg={4}>
                    <FormControl variant="outlined" fullWidth>
                        <TextField
                            label={step.textfield_title || 'Comments'}
                            value={step.comments || ''}
                            margin="dense"
                            name="comments"
                            disabled={disabled}
                            onChange={change}
                            style={{ marginTop: 12 }}
                            aria-describedby="outlined-weight-helper-text"
                            variant="outlined"
                            InputProps={{
                                inputProps: {
                                    maxLength: 100
                                },
                            }}
                        />
                    </FormControl>
                </Grid>
            </Grid>
        </div>
    );
}

function UploadBlock({ step, type, handleChange, save, disabled }) {
    const classes = useStyles();
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [submitted, setSubmitted] = useState(false);
    const { mobileView, showProgressDialog, closeProgressDialog, showSnackBar } = useContext(AppContext);

    const change = (event) => {
        // console.log(event.target.value);
        handleChange(event.target.name, event.target.value);
    }

    const select = (fileUploaded) => {
        setSelectedFiles(prev => ([...prev, fileUploaded]));
    }

    const upload = () => {
        setSubmitted(true);
        showProgressDialog(`Uploading ${selectedFiles.length} Images...`);
        let ids = [];
        let requests = selectedFiles.map(file => {
            const formData = new FormData();
            formData.append('file', file || null);
            return LeadService.addLeadStepDocument(step.lead_id, step.step_id, formData)
        });

        Promise.all(requests)
            .then(datas => {
                datas.forEach(data => {
                    if (data.success) {
                        ids.push(data.id);
                    }
                    closeProgressDialog();
                    if (ids.length === selectedFiles.length) {
                        save({ field1: ids.join() });
                    }
                })
            })
            .catch(error => {
                console.log(error);
                showSnackBar('Something went wrong. Please try again.', 'error', 1000);
            });
    }

    const remove = file => {
        // showConfirmDialog('Confirm Remove?', undefined, () => {
        //     closeConfirmDialog();
        //     LeadService.removeDocument(id, step.lead_id, step.step_id)
        //     .then(() => {
        //         fetchData();
        //     })
        //     .catch(error => {
        //         console.log(error);
        //         showSnackBar('Something went wrong while trying to remove file. Please try again', 'error');
        //     })
        // })
        setSelectedFiles(prev => ([...prev.filter(f => f !== file)]));
    }

    return (
        <div className={classes.sectionFull}>
            <Grid container spacing={2} justify="flex-start" alignItems="center">
                <Grid item xs={8} lg={12}>
                    <GridList cellHeight={230} className={classes.gridList} cols={mobileView ? 1 : 4}>
                        {
                            selectedFiles.map((data, i) => {
                                return (
                                    <GridListTile key={i}>
                                        <AddedImageHolder
                                            link={data || null}
                                            remove={() => {
                                                remove(data);
                                            }}
                                        />
                                    </GridListTile>
                                )
                            })
                        }
                        {
                            <GridListTile>
                                <AddImage
                                    disabled={disabled}
                                    error={false}
                                    title="Add"
                                    type={type}
                                    change={(file) => {
                                        select(file);
                                    }}
                                //remove={(file) => {
                                //    remove(file);
                                //}}
                                />
                            </GridListTile>
                        }
                    </GridList>
                </Grid>
                {/* <Grid item xs={4} lg={2}>
                    <Button fullWidth color="primary" variant="contained" onClick={handleClick} startIcon={<CloudUploadIcon />} style={{marginTop: 8}}>Upload</Button>
                    <input 
                        accept={type} 
                        type="file" 
                        onChange={upload} 
                        onClick={(event) =>  event.target.value = null} ref={hiddenFileInput} 
                        style={{display:'none'}} />
                </Grid> */}
                <Grid item xs={12} lg={4}>
                    <FormControl variant="outlined" fullWidth>
                        <TextField
                            label={step.textfield_title || 'Comments'}
                            disabled={disabled}
                            value={step.comments || ''}
                            margin="dense"
                            name="comments"
                            onChange={change}
                            style={{ marginTop: 12 }}
                            aria-describedby="outlined-weight-helper-text"
                            variant="outlined"
                            InputProps={{
                                inputProps: {
                                    maxLength: 100
                                },
                            }}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={4} lg={2}>
                    <Button
                        fullWidth
                        color="primary"
                        variant="contained"
                        onClick={upload}
                        disabled={submitted}
                        style={{ marginTop: 8 }}>Upload</Button>
                </Grid>
            </Grid>
        </div>
    );
}

function AddImage(props) {
    const hiddenFileInput = React.useRef(null);
    const [link, setLink] = React.useState();

    React.useEffect(() => {
        setLink(props.link);
    }, [props.link])

    const handleClick = () => {
        hiddenFileInput.current.click();
    }

    const change = (event) => {
        const fileUploaded = event.target.files[0];
        // setLink(URL.createObjectURL(fileUploaded));
        props.change(fileUploaded);
    }

    let classes = useStyles();
    return (
        <ButtonBase
            focusRipple
            disabled={props.disabled}
            className={classes.image}>
            <img
                src={link}
                height="200" width="200"
                alt="" />
            <span className={classes.imageBackdrop} />
            <span className={classes.imageButton} onClick={handleClick}>
                <Typography
                    component="span"
                    variant="subtitle1"
                    color="inherit"
                    className={classes.imageTitle}
                >
                    <p><AddCircleIcon fontSize="large" /></p>
                    {props.title}
                    <span className={classes.imageMarked} />
                </Typography>
                <input accept={props.type} type="file" onChange={change} ref={hiddenFileInput} onClick={(event) => event.target.value = null} style={{ display: 'none' }} />
            </span>
        </ButtonBase>
    )
}

function AddedImageHolder(props) {
    const [link, setLink] = React.useState(null);

    React.useEffect(() => {
        // setLink(props.link);
        setLink(window.URL.createObjectURL(props.link));
    }, [props.link])

    let classes = useStyles();

    const remove = () => {
        props.remove();
    }

    return (
        <ButtonBase
            focusRipple
            className={classes.image}>
            <img
                src={link}
                height="200" width="200"
                alt="" />
            <span className={classes.imageBackdrop} />
            <span className={classes.imageButtonBottom} onClick={remove}>
                <Typography
                    component="span"
                    variant="subtitle1"
                    color="inherit"
                    className={classes.imageTitle}
                >
                    <p style={{ margin: 0 }}><DeleteIcon fontSize="small" /></p>
                    {props.title}
                    <span className={classes.imageMarked} />
                </Typography>
            </span>
        </ButtonBase>
    )
}

function SubListBlock({ step, handleChange, disabled }) {
    const classes = useStyles();
    const [substeps, setSteps] = useState([]);
    const { showProgressDialog, closeProgressDialog } = useContext(AppContext);

    React.useEffect(() => {
        showProgressDialog('Fetching Checklist...');
        LeadService.getSubSteps(step.step_id)
            .then(data => {
                setSteps(data.map(d => ({ ...d, value: 0 })));
                closeProgressDialog();
            })
            .catch(error => {
                console.log(error);
                closeProgressDialog();
            })
        return () => {
            setSteps([]);
        }
    }, [true]);

    const change = substep => (event) => {
        // console.log(event.target.value);
        substep.value = event.target.checked ? 1 : 0;
        let steps = [...substeps.filter(s => s.id !== substep.id), substep];
        let value = {};
        steps.forEach(s => {
            value[s.id] = s.value;
        })
        // setSteps(steps => ([...steps.filter(s => s.id != substep.id), substep]));
        handleChange('field1', JSON.stringify(value));
    }

    const change2 = (event) => {
        handleChange(event.target.name, event.target.value);
    }

    return (
        <div className={classes.sectionFull}>
            <Grid container spacing={2} justify="flex-start" alignItems="center">
                <Grid item xs={12} lg={12}>
                    <FormGroup>
                        {
                            substeps.map((s, i) => {
                                if (s.type === 'check') {
                                    return <FormControlLabel
                                        key={i}
                                        disabled={disabled}
                                        control={
                                            <Checkbox
                                                icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                                                checkedIcon={<CheckBoxIcon fontSize="small" />}
                                                name={s.name}
                                            />
                                        }
                                        label={s.name}
                                        checked={s.value === 1}
                                        onChange={change(s)}
                                    />
                                }
                                return null;
                            })
                        }
                    </FormGroup>
                </Grid>
                <Grid item xs={12} lg={4}>
                    <FormControl variant="outlined" fullWidth>
                        <TextField
                            label={step.textfield_title || 'Comments'}
                            value={step.comments || ''}
                            margin="dense"
                            disabled={disabled}
                            name="comments"
                            onChange={change2}
                            aria-describedby="outlined-weight-helper-text"
                            variant="outlined"
                            InputProps={{
                                inputProps: {
                                    maxLength: 100
                                },
                            }}
                        />
                    </FormControl>
                </Grid>
            </Grid>
        </div>
    );
}

function PaymentBlock({ step, handleChange, disabled }) {
    const classes = useStyles();

    const change = (event) => {
        // console.log(event.target.value);
        handleChange(event.target.name, event.target.value);
    }

    return (
        <div className={classes.sectionFull}>
            <Grid container spacing={2} justify="flex-start" alignItems="center">
                <Grid item xs={12} lg={4}>
                    <FormControl variant="outlined" fullWidth>
                        <TextField
                            label={step.textfield_title || 'Comments'}
                            value={step.comments || ''}
                            margin="dense"
                            disabled={disabled}
                            name="comments"
                            onChange={change}
                            aria-describedby="outlined-weight-helper-text"
                            variant="outlined"
                            InputProps={{
                                inputProps: {
                                    maxLength: 100
                                },
                            }}
                        />
                    </FormControl>
                </Grid>
            </Grid>
        </div>
    );
}

function CourierBlock({ step, save, handleChange, disabled }) {
    const classes = useStyles();
    const { showProgressDialog, showSnackBar, showConfirmDialog, closeConfirmDialog } = useContext(AppContext);
    const [submitted, setSubmitted] = useState(false);

    const change = (event) => {
        // console.log(event.target.value);
        handleChange(event.target.name, event.target.value);
    }

    const isEmpty = (field) => {
        if (typeof field === 'string')
            return (!field || field.length === 0 || !field.trim());
        else {
            return (!field);
        }
    };


    const saveStep = () => {
        if (isEmpty(step.field1) || isEmpty(step.field2) || isEmpty(step.field3)) {
            showSnackBar('Please fill in all the Required Fields', 'error');
        } else {
            showConfirmDialog('Confirm tracking details?', undefined, () => {
                closeConfirmDialog();
                setSubmitted(true);
                showProgressDialog(`Updating details...`);
                save({});
            })
        }
    }

    return (
        <div className={classes.sectionFull}>
            <Grid container spacing={2} justify="flex-start" alignItems="flex-start">
                <Grid item xs={12} lg={3}>
                    <FormControl variant="outlined" fullWidth>
                        <TextField
                            label="Name of Courier"
                            value={step.field1 || ''}
                            margin="dense"
                            name="field1"
                            required
                            disabled={disabled}
                            onChange={change}
                            aria-describedby="outlined-weight-helper-text"
                            variant="outlined"
                            InputProps={{
                                inputProps: {
                                    maxLength: 30
                                },
                            }}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={12} lg={3}>
                    <SingleDate
                        margin="dense"
                        required
                        disabled={disabled}
                        label="Date of Dispatch"
                        date={step.field2 ? moment(step.field2) : null}
                        setDate={date => handleChange('field2', date.format(format_without_time))}
                    />
                </Grid>
                <Grid item xs={12} lg={3}>
                    <FormControl variant="outlined" fullWidth>
                        <TextField
                            label="POD number (Tracking No)"
                            value={step.field3 || ''}
                            margin="dense"
                            name="field3"
                            disabled={disabled}
                            onChange={change}
                            required
                            aria-describedby="outlined-weight-helper-text"
                            variant="outlined"
                            InputProps={{
                                inputProps: {
                                    maxLength: 20
                                },
                            }}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={12} lg={3}>
                    <FormControl variant="outlined" fullWidth>
                        <TextField
                            label="Tracking Link"
                            value={step.field4 || ''}
                            margin="dense"
                            disabled={disabled}
                            name="field4"
                            onChange={change}
                            aria-describedby="outlined-weight-helper-text"
                            variant="outlined"
                            InputProps={{
                                inputProps: {
                                    maxLength: 200
                                },
                            }}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={12} lg={2}>
                    <Button
                        fullWidth
                        color="primary"
                        variant="contained"
                        onClick={saveStep}
                        disabled={disabled || submitted}
                        style={{ marginTop: 8 }}>Save</Button>
                </Grid>
            </Grid>
        </div>
    );
}

function DummyBlock() {
    const classes = useStyles();

    return (
        <div className={classes.sectionFull}>
            <Grid container spacing={2} justify="flex-start" alignItems="center">
                {/* <Grid item xs={12} lg={4}>
                    <FormControl variant="outlined" fullWidth>
                        <TextField
                            label={step.textfield_title || 'Comments'}
                            value={step.comments || ''}
                            margin="dense"
                            name="comments"
                            onChange={change}
                            aria-describedby="outlined-weight-helper-text"
                            variant="outlined"
                            InputProps={{
                                inputProps: {
                                    maxLength: 100
                                },
                            }}
                        />
                    </FormControl>
                </Grid> */}
            </Grid>
        </div>
    );
}

const CustomStepContent = (props) => {
    const { step, lead, handleChange, save, disabled } = props;
    const [description, setDescription] = useState('');
    const classes = useStyles();
    const getCenterBlock = () => {
        switch (step.step_ui_type) {
            case STEP_TYPE.approval:
                return <ApprovalBlock step={step} handleChange={handleChange} disabled={disabled} />;
            case STEP_TYPE.email:
                return <EmailBlock step={step} handleChange={handleChange} disabled={disabled} />;
            case STEP_TYPE.email_custom_file:
            case STEP_TYPE.welcome:
                return <EmailBlock2 step={step} handleChange={handleChange} disabled={disabled} />;
            case STEP_TYPE.upload_img:
                return <UploadBlock step={step} type={'image/*'} save={save} handleChange={handleChange} disabled={disabled} />;
            case STEP_TYPE.receipt:
                return <ReceiptBlock step={step} handleChange={handleChange} disabled={disabled} />;
            case STEP_TYPE.code:
                return <CodeBlock step={step} handleChange={handleChange} disabled={disabled} />;
            case STEP_TYPE.code2:
                return <CodeBlock2 step={step} handleChange={handleChange} disabled={disabled} />;
            case STEP_TYPE.payment_link:
                return <PaymentBlock step={step} handleChange={handleChange} disabled={disabled} />;
            case STEP_TYPE.check:
                return <CheckBlock step={step} handleChange={handleChange} disabled={disabled} />;
            case STEP_TYPE.sublist:
                return <SubListBlock step={step} handleChange={handleChange} disabled={disabled} />;
            case STEP_TYPE.courier:
                return <CourierBlock step={step} save={save} handleChange={handleChange} disabled={disabled} />;
            default:
                return <DummyBlock />;
        }
    }

    React.useEffect(() => {
        if (step) {
            let desc = step.description || '';
            while (desc.includes('{')) {
                let field = desc.substring(desc.indexOf('{') + 1, desc.indexOf('}'));
                let value = lead[field];
                desc = desc.replace(`{${field}}`, value);
            }
            setDescription(desc);
        }
    }, [step]);

    return (
        <div className={classes.root}>
            {
                step && description && (
                    <div>
                        <ListItemText
                            primary={`Description: ${description || '-'}`}
                            secondary={`Ownership: ${step.ownership ? USERTYPE_TO_TEXT[step.ownership] : '-'} ${step.ownership_code || ''}`}
                        />
                    </div>
                )
            }
            {step && getCenterBlock()}
        </div>
    )
};

export default CustomStepContent;