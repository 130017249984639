import React, { useContext, useEffect, useState } from "react";
import { Card, CardHeader, Divider, CardContent, makeStyles, Button, CardActions, List, ListItem, Typography, ListItemText, Grid, CircularProgress, Avatar, ListItemAvatar, IconButton } from "@material-ui/core";
import { BootstrapTooltip, getTimeInIST } from "../Utils";
// import VisibilityIcon from '@material-ui/icons/Visibility';
// import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import { Link as RouterLink } from 'react-router-dom';
import PropTypes from "prop-types";
import RefreshIcon from '@material-ui/icons/Refresh';
import clsx from 'clsx';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
// import moment from "moment";
import { LeadService } from "../Services/LeadService";
import { AppContext } from "../AppContextProvider";
import { USERTYPES, USERTYPE_TO_TEXT } from "../Constants";
import ItemSelection from "../components/ItemSelection";
import DeleteIcon from '@material-ui/icons/Delete';

const useStyles = makeStyles(theme => ({
    root: {
        minHeight: 100,
    },
    icon: {
        padding: 2,
        marginLeft: 10
    },
    content: {
        paddingTop: 0,
        paddingBottom: 0,
        minHeight: 100,
        paddingLeft: 0,
        paddingRight: 0,
    },
    itemDivider: {
        borderBottomColor: 'rgba(0,0,0,0.1)'
    },
    actions: {
        paddingTop: 0,
        justifyContent: 'flex-end'
    },
    arrowForwardIcon: {
        marginLeft: theme.spacing(1)
    },
    select: {
        marginTop: 8
    },
    griditem: {
        cursor: 'move'
    },
    loader: {
        marginTop: 40
    },
    inner: {
        overflow: 'auto',
    },
    comments: {
        display: 'flex',
        alignItems: 'center'
    },
    tableHead: {
        fontSize: 16,
        color: '#263238',
        fontWeight: 500,
        lineHeight: '1.5rem'
    },
    tableCell: {
        fontSize: '0.875rem',
        color: '#263238',
        fontWeight: 400,
        lineHeight: '1.43'
    }
}));

const Tickets = ({ count, counter, ...props }) => {
    const classes = useStyles();
    const sortOptions = [{
        id: 0,
        display: 'Newest First',
        sort: 'created_date',
        direction: 'desc'
    }, {
        id: 1,
        display: 'Oldest First',
        sort: 'created_date',
        direction: 'asc'
    }, {
        id: 2,
        display: 'Task Owner',
        sort: 'current_step.ownership',
        direction: 'asc'
    }];
    const {openLead, state, showConfirmDialog, closeConfirmDialog, showConfirmDialog1D, closeConfirmDialog1D, showProgressDialog, closeProgressDialog, showSnackBar} = useContext(AppContext);
    const [loading, setLoading] = useState(false);
    const {className} = props;
    const [data, setData] = useState([]);
    const [selectedOrder, setSelectedOrder] = useState(sortOptions[0]);
    const [rows, setRows] = useState([]);
    const userFilter = [USERTYPES.BRANCH, USERTYPES.STATEMG, USERTYPES.SALEHD].includes(state.user.EmpUtype) ? state.user.EmpUcode.toLowerCase() : null;
    const showDeleteOption = [USERTYPES.BRANCH].includes(state.user.EmpUtype);

    React.useEffect(() => {
        refresh();
    }, [true, counter]);

    const removeSelected = (id, name) => {
        showConfirmDialog(`Confirm removal of lead ${name}?`, undefined, () => {
            showProgressDialog(`Removing...`);
            closeConfirmDialog();
            LeadService.removeLead(id)
            .then(data => {
                console.log(data)
                if(data.success){
                    refresh();
                } else if (data.error) {
                    showConfirmDialog1D(data.error ? data.error : 'Something went wrong. Please try again later.', undefined, () => {
                        closeConfirmDialog1D();
                    }, 'OK')
                }
            })
            .catch(error => {
                console.error(error)
                showSnackBar(error.error ? error.error : 'Something went wrong. Please try again later.', 'error');
            })
            .finally(() => {
                closeProgressDialog();
            })
        })
    }

    const refresh = () => {
        setLoading(true);
        LeadService.getLeadList('1', userFilter, '', 'full_name', 'desc', 0, count)
        .then((data) => {
            console.log(data);
            if(data.length > 0){
                sortBy(data);
            }
            setLoading(false);
        }).catch(error => {
            console.log(error);
            setLoading(false);
        })
    }

    useEffect(() => {
        if(selectedOrder && data.length > 0) {
            console.log(selectedOrder)
            sortBy(data);
        }
    }, [selectedOrder])

    const sortBy = (data) => {
        let orderBy = selectedOrder.sort;
        if('asc' === selectedOrder.direction){
            if(orderBy.includes('.')){
                let parts = orderBy.split('.')
                data.sort((a, b) => (a[parts[0]][parts[1]] > b[parts[0]][[parts[1]]]) ? 1 : -1);
            } else {
                data.sort((a, b) => (a[orderBy] > b[orderBy]) ? 1 : -1);
            }
		} else {
            if(orderBy.includes('.')){
                let parts = orderBy.split('.')
                data.sort((a, b) => (a[parts[0]][parts[1]] < b[parts[0]][[parts[1]]]) ? 1 : -1);
            } else {
                data.sort((a, b) => (a[orderBy] < b[orderBy]) ? 1 : -1);
            }
		}
        setData(data);
        setRows(data.length > 0 ? 
                data.map((row, index) => (
                    <ListItem
                        classes={{ divider: classes.itemDivider }}
                        style={{paddingLeft: 10, paddingRight: 10}}
                        divider
                        dense
                        key={index}
                    >
                        <ListItemAvatar>
                            <Avatar>
                                {
                                    row.photo ? 
                                        <img height={50} width={50} src={row.photo.link} alt={row.full_name}/>
                                    :
                                        row.full_name.substring(0,1)
                                }
                            </Avatar>
                        </ListItemAvatar>
                        <BootstrapTooltip arrow title={`Click to view Details`}>
                            <ListItemText
                                disableTypography
                                primary={
                                    <Typography align="left" component="legend"><b>{`Lead: `}</b>{row.full_name}</Typography>
                                }
                                secondary={
                                        <div style={{display: 'flex', alignItems: 'center', cursor: 'pointer'}}>
                                            <Typography 
                                                variant="body2" 
                                                style={{flex: 1.5}}
                                                component="div" >
                                                {`Branch: ${row.branch || '-'}`}<br/>
                                                {`Owner: ${row.current_step ? USERTYPE_TO_TEXT[row.current_step.ownership] : '-'}`}
                                            </Typography>
                                            <Typography 
                                                variant="body1"
                                                color="secondary" 
                                                style={{marginRight: 48, flex: 2}} 
                                                align="left" 
                                                component="div">
                                                {`Current Task ${row.current_step ? `${row.current_step.name} (Started: ${getTimeInIST(row.current_step.created_date).fromNow()})` : '-'}`}
                                            </Typography>
                                            <Typography variant="caption" align="right" component="div" style={{flex: 1}}>
                                                {`Created: ${row.created_date ? getTimeInIST(row.created_date).fromNow() : '-'}`}
                                                { 
                                                    showDeleteOption && (
                                                        <IconButton 
                                                            aria-label={`remove`} 
                                                            className={classes.icon} 
                                                            size="small" 
                                                            onClick={(e) => {
                                                                e.stopPropagation(); 
                                                                removeSelected(row.id, row.full_name)
                                                            }}>
                                                            <DeleteIcon />
                                                        </IconButton>
                                                    )
                                                }
                                            </Typography>
                                        </div>
                                }
                                onClick={(e) => {
                                    if(row.id){
                                        openDialog(row.id);
                                    }
                                }} />
                            </BootstrapTooltip>
                    </ListItem>
                ))
                :
                (
                    <ListItem
                        classes={{ divider: classes.itemDivider }}
                        style={{paddingLeft: 48, paddingRight: 10}}
                        dense
                    >
                        <ListItemText
                            primary={'None'}
                            />
                    </ListItem>
                ));
    }

    const openDialog = (id) => {
        openLead(id, (shouldRefresh) => {
            refresh();
        });
    }

    return (
        <Grid item xl={12} lg={12} xs={12}>
            <Card 
                className={clsx(classes.root, className)}
                //onMouseEnter={() => setIsShown(true)}
                //onMouseLeave={() => setIsShown(false)}
                >
                <CardHeader 
                    title={`Ongoing Leads`}
                    className={classes.griditem}
                    titleTypographyProps={{align: 'left'}}
                    action={
                        <div style={{display: 'flex', justifyContent: 'space-between'}}>
                            <div style={{width: '20vw'}}>
                            <ItemSelection
                                clearable={false} 
                                label='Sort By' 
                                value={selectedOrder || undefined} 
                                optionLabel='display' 
                                options={sortOptions} 
                                getOptionSelected={(option, value) => value.id === option.id}
                                style={{marginRight: 16 }}
                                selected={(option) => {
                                    if(selectedOrder.id !== option.id){
                                        setSelectedOrder(option);
                                    }
                                }}/>
                            </div>
                            <IconButton onClick={refresh}>
                                <RefreshIcon/>
                            </IconButton>
                        </div>
                    }/>
                <Divider />
                <CardContent className={classes.content}>
                    {
                        loading ?
                            <div className={classes.loader}>
                                <CircularProgress/>
                            </div>
                        :
                        <div className={classes.inner}>
                            <List>
                                { rows }
                            </List>
                        </div>
                    }
                </CardContent>
                <CardActions className={classes.actions}>
                    <Button
                        color="inherit"
                        component={RouterLink}
                        size="small"
                        variant="text"
                        to="/leads"
                    >
                        See all
                        <ArrowForwardIcon className={classes.arrowForwardIcon} />
                    </Button>
                </CardActions>
            </Card>
        </Grid>
    );
};

Tickets.propTypes = {
    count: PropTypes.number.isRequired,
};

export default Tickets;