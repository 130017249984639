import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from 'prop-types';
import { Grid, TextField } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
    sectionHeading: {
        fontSize: theme.typography.pxToRem(16),
        fontWeight: theme.typography.fontWeightMedium,
    },
    heading: {
        fontSize: theme.typography.pxToRem(14),
        fontWeight: theme.typography.fontWeightMedium,
    },
    subheading: {
        fontSize: theme.typography.pxToRem(14),
        fontWeight: theme.typography.fontWeightRegular,
        textAlign: "left"
    },
    dialogContent: {
        paddingTop: 0
    }
}));

const DialogTitle = (props => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6" style={{paddingRight: 24}}>{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles(theme => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles(theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

export default function UpdateLeadDialog(props) {
    const {subtitle, type, field, label, currentValue} = props;
    const [open, setOpen] = React.useState(false);  
    const [title, setTitle] = React.useState(null);
    const [value, setValue] = React.useState(undefined);
    const classes = useStyles();

    React.useEffect(() => {
        if(props.open){
            setOpen(props.open);
            setTitle(props.title);
            setValue(currentValue || (type === 'number' ? 0 : ''))
        } else {
          setOpen(props.open);
        }
    }, [props.open]);

    const handleClose = () => {
        props.close();
        setTitle(null);
        setValue(undefined)
        setOpen(false);
    };

    const handleSave = () => {
      props.save(field, value)
      handleClose()
    }

    const handleChange = e => {
      e.preventDefault();
      let value = e.target.value;
      setValue(value);
    }

    return (
        <Dialog fullWidth={props.fullWidth !== undefined ? props.fullWidth : true} maxWidth={props.maxWidth !== undefined ?  props.maxWidth : "lg"} onClose={handleClose} aria-labelledby="customized-dialog-title" open={open}>
            <DialogTitle id="customized-dialog-title" onClose={handleClose} classes={classes}>
              <Typography variant="h6" component="div">
                {title}
              </Typography>
              <Typography variant="overline" component="div">
                {subtitle}
              </Typography>
            </DialogTitle>
            <DialogContent dividers style={{paddingTop: 0}}>
             <Grid container spacing={1}>
                  <Grid item xs={12} lg={12}>
                    <TextField
                      id="value"
                      label={label}
                      placeholder={label}
                      name="value"
                      defaultValue={value}
                      className={classes.textField}
                      margin="dense"
                      variant="outlined"
                      style={{marginLeft: 8, paddingRight: 10}}
                      onChange={handleChange}
                      required
                      fullWidth
                      InputProps={{
                          type: type
                        }}
                    />
                  </Grid>
                </Grid>
            </DialogContent> 
            <DialogActions>
                <Button autoFocus onClick={handleClose} color="primary">
                    Cancel
                </Button>
                <Button autoFocus onClick={handleSave} color="primary">
                    Save
                </Button>
            </DialogActions>
        </Dialog>
    );
}

UpdateLeadDialog.prototype = {
    open: PropTypes.bool.isRequired,
    title: PropTypes.string.isRequired,
    close: PropTypes.func.isRequired,
    save: PropTypes.func.isRequired,
    showAmount: PropTypes.bool.isRequired,
    mode: PropTypes.string.isRequired,
    minAmount: PropTypes.number.isRequired,
    bankLedgers: PropTypes.array,
    currentDisbursementLedger: PropTypes.object.isRequired,
    maxWidth: PropTypes.any,
    fullWidth: PropTypes.bool
};