import React, { useContext } from "react";
import "../css/home.css";
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import {
	Fab,
	Grid,
	makeStyles,
} from '@material-ui/core';
import { AppContext } from "../AppContextProvider";
import Tickets from "./Tickets";
import { BootstrapTooltip } from "../Utils";
import AddIcon from '@material-ui/icons/Add';

const useStyles = makeStyles(theme => ({
    fab: {
        margin: theme.spacing(1),
        position: 'fixed',
        bottom: theme.spacing(2),
        right: theme.spacing(2),
    },
}));

let counter = 1;

function Home(props){
	const classes = useStyles();
	const {state, setTitle, openNewLead} = useContext(AppContext);
	
	React.useEffect(() => {
		if(state.isAuthenticated){
			setTitle('Dashboard');
		}
		return () => {
		}
	}, [state.isAuthenticated, true]);

	const handleClick = () => {
		openNewLead(null, () => {
			counter++;
		});
	}

	return (
		<div className="Home">
			<div className="lander">
				<div>
					{
						state.isAuthenticated && (
							<Grid container direction="row" spacing={1} justify={"center"}>
								<Grid item data-tour="license" xs={12} lg={12} xl={10}>
									<Tickets count={20} counter={counter}/>
								</Grid>
							</Grid>
						)
					}
				</div>
				<BootstrapTooltip arrow title="Add New Lead">
					<Fab variant="extended" color="primary" aria-label="Add" className={classes.fab} onClick={handleClick}>
						<AddIcon />
						Add New Lead
					</Fab>
				</BootstrapTooltip>
			</div>
		</div>
	);
}

export default Home;