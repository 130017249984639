import React, { useContext, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Button, TextField, Card, CardHeader, CardContent, Divider, CardActions, colors, Typography, FormControlLabel, Checkbox, Hidden } from "@material-ui/core";
import clsx from 'clsx';
import { AppContext } from "../AppContextProvider";
import { MasterService } from "../Services/MasterService";
import { Skeleton } from "@material-ui/lab";
const useStyles = makeStyles(theme => ({
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        float: 'left'
    },
    root: {},
    saveButton: {
        color: theme.palette.white,
        backgroundColor: colors.green[600],
        '&:hover': {
            backgroundColor: colors.green[900]
        }
    },
    actions: {
        float: 'right',
    },
    stateSelection: {
        [theme.breakpoints.up('md')]: {
            marginLeft: theme.spacing(1),
        },
    }
}));

export default function ManageNotifications(props) {
    const classes = useStyles();
    const {setTitle, showConfirmDialog, showSnackBar, closeConfirmDialog, closeProgressDialog, showProgressDialog} = useContext(AppContext);
    const [config, setConfig] = useState({});
    const [stepConfig, setStepConfig] = useState([]);

    React.useEffect(() => {
        setTitle('Manage Notifications');
        fetchData();
    }, [true]);
    
    const fetchData = () => {
        showProgressDialog();
        Promise.all([MasterService.getConfig(), MasterService.getStepConfig()])
        .then(datas => {
            if(datas[0]){
                setConfig(datas[0]);
            }
            if(datas[1]){
                setStepConfig(datas[1]);
            }
            closeProgressDialog();
        })
        .catch(error => {
            console.log(error);
            closeProgressDialog();
            showSnackBar('Something went wrong while fetching. Please try again.');
        })
    }

    const handleChange = e => {
        let name = e.target.name;
        let pref = e.target.checked ? 1 : 0;
        // console.log(category, e.target.name);
        showProgressDialog('Updating Preference...');
        MasterService.updatePreference({name, value : pref})
        .then(data => {
            if(data.status){
                showSnackBar('Preference updated', 'info');
                setConfig(prev => ({...prev, [name]: pref}));
            }
            closeProgressDialog();
        })
        .catch(error => {
            console.log(error);
            showSnackBar('Failed to update preference. Please try again.', 'error');
            closeProgressDialog();
        })
    };
    
    const handleStepChange = step => e => {
        let name = e.target.name;
        let pref = e.target.type === 'checkbox' ? e.target.checked ? 1 : 0 : e.target.value;
        setStepConfig(prev => (prev.map(s => s.id === step.id ? {...s, [name] : pref, modified : true} : s )));
        // // console.log(category, e.target.name);
        
    };

    const saveStepConfig = () => {
        showConfirmDialog('Confirm Save Configuration?', undefined, () => {
            closeConfirmDialog();
            showProgressDialog('Updating Step Preferences...');
            let steps = stepConfig.filter(s => s.modified).map(s => ({id :s.id, notification_enabled : s.notification_enabled, notification_email_enabled: s.notification_email_enabled, notification_sms_enabled: s.notification_sms_enabled, additional_email: s.additional_email, additional_sms_nos: s.additional_sms_nos}));
            MasterService.updateStepPreference(steps)
            .then(data => {
                if(data.status){
                    showSnackBar('Preference updated', 'info');
                }
                closeProgressDialog();
            })
            .catch(error => {
                console.log(error);
                showSnackBar('Failed to update preference. Please try again.', 'error');
                closeProgressDialog();
            })
        })
    }

    return (
        <div className="Home">
            <div className="lander">
                <div >
                    <Grid container direction="row" spacing={4} justify="center">
                        <Grid item xl={10} lg={10} xs={12}>
                            <Card className={clsx(classes.root)}>
                                <CardHeader 
                                    title="Manage Notifications"
                                    titleTypographyProps={{align: 'left'}} />
                                <Divider />
                                <CardContent>
                                    <Grid container justify="flex-start" alignItems="flex-start" spacing={1}>
                                        <Grid item xs={12} sm={6} md={6} lg={6}>
                                            <Typography variant="body1" align="left" style={{padding: 12}}>General Settings for OnBoarding</Typography>
                                        </Grid>
                                        <Grid item xs={6} sm={2} md={2} lg={2}>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        checked={config.notification_email_enabled === 1}
                                                        onChange={handleChange}
                                                        name="notification_email_enabled"
                                                        color="primary"
                                                    />
                                                }
                                                label="Email"
                                            />
                                        </Grid>
                                        <Grid item xs={6} sm={2} md={2} lg={2}>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        checked={config.notification_sms_enabled === 1}
                                                        onChange={handleChange}
                                                        name="notification_sms_enabled"
                                                        color="primary"
                                                    />
                                                }
                                                label="SMS"
                                            />
                                        </Grid>
                                    </Grid>
                                </CardContent>
                                {/* <Divider />
                                <CardActions className={classes.actions}>
                                    <Button variant="contained" className={classes.saveButton} color="primary" onClick={submitProfile}>
                                        Save
                                    </Button>
                                </CardActions> */}
                            </Card>
                        </Grid>
                        <Grid item xl={10} lg={12} xs={12}>
                            <Card className={clsx(classes.root)}>
                                <CardHeader 
                                    title="Step Configuration"
                                    titleTypographyProps={{align: 'left'}} />
                                <Divider />
                                <CardContent>
                                    <Hidden smDown>
                                        <Grid container spacing={1} justify="flex-start">
                                            <Grid item xs={4} sm={6} md={6} lg={2}>
                                                <Typography variant="h5" align="center">Step</Typography>
                                            </Grid>
                                            <Grid item xs={4} sm={6} md={6} lg={2}>
                                                <Typography variant="h5" align="center">Notification Enabled</Typography>
                                            </Grid>
                                            <Grid item xs={4} sm={2} md={2} lg={1}>
                                                <Typography variant="h5">Email</Typography>    
                                            </Grid>
                                            <Grid item xs={4} sm={2} md={2} lg={1}>
                                                <Typography variant="h5">SMS</Typography>
                                            </Grid>
                                            <Grid item xs={4} sm={2} md={2} lg={3}>
                                                <Typography variant="h5">Email IDs</Typography>    
                                            </Grid>
                                            <Grid item xs={4} sm={2} md={2} lg={3}>
                                                <Typography variant="h5">SMS Numbers</Typography>
                                            </Grid>
                                            <Grid item xs={12} lg={12}>
                                                <Divider style={{marginTop: 8, marginBottom: 8}}/>
                                            </Grid>
                                        </Grid>
                                        {
                                            stepConfig.length === 0 && 
                                                Array.from(Array(5), (_, i) => 
                                                    <Grid key={i} container justify="flex-start">
                                                        <Grid item xs={12} sm={12} md={12} lg={12}>
                                                            <Skeleton width={"100%"} height={50}/>
                                                        </Grid>
                                                    </Grid>)
                                        }
                                        {
                                            stepConfig.map(step => (
                                                <Grid key={step.id} container spacing={1} justify="flex-start" alignItems="flex-start">
                                                    <Grid item xs={12} sm={6} md={6} lg={2}>
                                                        <Typography variant="body1" align="left" style={{padding: 12}}>{step.name}</Typography>
                                                        { 
                                                            (step.id === 1 || step.id === 2 || step.id === 3) && (
                                                                <Typography variant="caption" color="textSecondary" align="left" style={{padding: 12}}>If enabled, by default notifies the selected member from the Lead Form</Typography>
                                                            )
                                                        }
                                                    </Grid>
                                                    <Grid item xs={6} sm={2} md={2} lg={2}>
                                                        <Checkbox
                                                            checked={step.notification_enabled === 1}
                                                            onChange={handleStepChange(step)}
                                                            name="notification_enabled"
                                                            color="primary"
                                                        />
                                                    </Grid>
                                                    <Grid item xs={6} sm={2} md={2} lg={1}>
                                                        <Checkbox
                                                            checked={step.notification_email_enabled === 1}
                                                            disabled={step.notification_enabled === 0}
                                                            onChange={handleStepChange(step)}
                                                            name="notification_email_enabled"
                                                            color="primary"
                                                        />
                                                    </Grid>
                                                    <Grid item xs={6} sm={2} md={2} lg={1}>
                                                        <Checkbox
                                                            checked={step.notification_sms_enabled === 1}
                                                            onChange={handleStepChange(step)}
                                                            disabled={step.notification_enabled === 0}
                                                            name="notification_sms_enabled"
                                                            color="primary"
                                                        />
                                                    </Grid>
                                                    <Grid item xs={6} sm={2} md={2} lg={3}>
                                                        <TextField  
                                                            name="additional_email"
                                                            variant="outlined" 
                                                            defaultValue={step.additional_email ? step.additional_email : ''} 
                                                            fullWidth 
                                                            disabled={step.notification_enabled === 0 || step.notification_email_enabled === 0}
                                                            type="text"
                                                            helperText="Comma Separated"
                                                            onChange={handleStepChange(step)}
                                                            InputProps={{
                                                                inputProps: {
                                                                    maxLength: 200
                                                                },
                                                            }}/>
                                                    </Grid>
                                                    <Grid item xs={6} sm={2} md={2} lg={3}>
                                                        <TextField  
                                                            name="additional_sms_nos"
                                                            variant="outlined" 
                                                            defaultValue={step.additional_sms_nos ? step.additional_sms_nos : ''} 
                                                            disabled={step.notification_enabled === 0 || step.notification_sms_enabled === 0}
                                                            fullWidth 
                                                            type="text"
                                                            helperText="Comma Separated"
                                                            onChange={handleStepChange(step)}
                                                            InputProps={{
                                                                inputProps: {
                                                                    maxLength: 100
                                                                },
                                                            }}/>
                                                    </Grid>
                                                </Grid>
                                            ))
                                        }
                                    </Hidden>
                                    <Hidden mdUp>
                                        <Grid container justify="flex-start">
                                            <Grid item xs={12}>
                                                <Typography variant="h5" align="center">Step Configuration</Typography>
                                            </Grid>
                                            {
                                                stepConfig.length === 0 && 
                                                    Array.from(Array(5), (_, i) => 
                                                        <Grid key={i} container justify="flex-start">
                                                            <Grid item xs={12} sm={12} md={12} lg={12}>
                                                                <Skeleton width={"100%"} height={50}/>
                                                            </Grid>
                                                        </Grid>)
                                            }
                                            {
                                                stepConfig.map(step => (
                                                    <Grid key={step.id} container justify="flex-start" alignItems="center">
                                                        <Grid item xs={12}>
                                                            <Typography variant="body1" align="left" style={{padding: 12}}>{step.name}</Typography>
                                                        </Grid>
                                                        <Grid item xs={4}>
                                                            <Typography variant="h5">Notification Enabled</Typography>    
                                                        </Grid>
                                                        <Grid item xs={8}>
                                                            <Checkbox
                                                                checked={step.notification_enabled === 1}
                                                                onChange={handleStepChange(step)}
                                                                name="notification_enabled"
                                                                color="primary"
                                                            />
                                                        </Grid>
                                                        <Grid item xs={4}>
                                                            <Typography variant="h5">Email</Typography>    
                                                        </Grid>
                                                        <Grid item xs={8}>
                                                            <Checkbox
                                                                checked={step.notification_email_enabled === 1}
                                                                onChange={handleStepChange(step)}
                                                                disabled={step.notification_enabled === 0}
                                                                name="notification_email_enabled"
                                                                color="primary"
                                                            />
                                                        </Grid>
                                                        <Grid item xs={4}>
                                                            <Typography variant="h5">SMS</Typography>
                                                        </Grid>
                                                        <Grid item xs={8}>
                                                            <Checkbox
                                                                checked={step.notification_sms_enabled === 1}
                                                                onChange={handleStepChange(step)}
                                                                disabled={step.notification_enabled === 0}
                                                                name="notification_sms_enabled"
                                                                color="primary"
                                                            />
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <Divider style={{marginTop: 8, marginBottom: 8}}/>
                                                        </Grid>
                                                    </Grid>
                                                ))
                                            }
                                        </Grid>
                                    </Hidden>
                                </CardContent>
                                <Divider />
                                <CardActions className={classes.actions}>
                                    <Button variant="contained" className={classes.saveButton} color="primary" onClick={saveStepConfig}>
                                        Save
                                    </Button>
                                </CardActions>
                            </Card>
                        </Grid>
                    </Grid>
                </div>
            </div>
        </div>
    );
}
 